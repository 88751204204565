import { Component, OnInit, Input } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  FormArray,
  FormGroup,
} from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Dashboard } from 'src/app/shared/models/entities/analytics/dashboard.model';
import { Exception } from 'src/app/shared/models/exception';
import { DashboardConfig } from '../shared/dashboard-config.model';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { DateService } from 'src/app/core/date.service';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { DashboardSettingsService } from 'src/app/analytics/dashboards/settings/dashboard-settings.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { BehaviorSubject } from 'rxjs';
import { PerformersService } from 'src/app/settings-app/shared/performers/performers.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { BoardNavigation } from 'src/app/settings-app/boards/model/board.model';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Component({
  selector: 'wp-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss'],
  providers: [DashboardSettingsService, PerformersService],
})
export class DashboardSettingsComponent implements OnInit {
  @Input() dashboard: Dashboard;

  public grantsAreAllowed: boolean;

  public isSaving$ = new BehaviorSubject<boolean>(false);

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    isActive: false,
    showInAnalytics: false,
    changeViewPeriodAllowed: false,
    defaultViewPeriod: null,
    grants: new FormArray([]),
    navigations: new FormArray([]),
  });

  public okTitle: string;
  public headerTitle: string;
  public isCreationMode: boolean;
  public isLoading: boolean;
  public configuration: DashboardConfig;
  public navigationResponse: BoardNavigation[];

  public get permissions(): FormArray {
    return this.form.get('grants') as FormArray;
  }

  public get navigations(): FormArray {
    return this.form.get('navigations') as FormArray;
  }

  constructor(
    private app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private dateService: DateService,
    private navigationService: NavigationService,
    private dashboardSettingsService: DashboardSettingsService,
    private performersService: PerformersService,
  ) {
    this.grantsAreAllowed = this.app.checkPermission(
      'DashboardPublish',
      'My',
      PermissionType.Execute,
    );
  }

  ngOnInit(): void {
    if (!this.dashboard) {
      const defaultConfiguration: DashboardConfig = {
        widgets: [],
        defaultViewPeriod: null,
        changeViewPeriodAllowed: false,
      };

      this.configuration = defaultConfiguration;
      this.isCreationMode = true;
      this.navigationResponse = [];
    } else {
      this.isCreationMode = false;
      this.load();
    }

    this.okTitle = this.translate.instant(
      this.isCreationMode ? 'shared.actions.create' : 'shared.actions.save',
    );
    this.headerTitle = this.translate.instant(
      this.isCreationMode
        ? 'analytics.dashboards.settings.headerCreation'
        : 'analytics.dashboards.settings.headerUpdating',
    );
  }

  /** Confirm modal. */
  public ok = (): void => {
    this.validateNavigations();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      this.performersService.detectChanges();
      return;
    }

    this.isSaving$.next(true);

    this.configuration.changeViewPeriodAllowed =
      this.form.value.changeViewPeriodAllowed;

    // TODO Совместимость
    this.configuration.defaultViewPeriod = this.form.value.defaultViewPeriod
      ? {
          From: this.form.value.defaultViewPeriod.from,
          To: this.form.value.defaultViewPeriod.to,
          PeriodType: this.form.value.defaultViewPeriod.periodType,
        }
      : null;

    const dashboard = {
      id: this.isCreationMode ? Guid.generate() : this.dashboard.id,
      ownerId: this.isCreationMode
        ? this.app.session.user.id
        : this.dashboard.owner.id,
      grants: this.dashboardSettingsService.getPermissionsSavingArray(
        this.permissions.value,
      ),
      configuration: JSON.stringify(this.configuration),
      name: this.form.value.name,
      description: this.form.value.description,
      isActive: this.form.value.isActive,
      showInAnalytics: this.form.value.showInAnalytics,
      navigations: this.navigations.value.map((navigationItem) => ({
        area: navigationItem.area.key,
        group: navigationItem.group.key,
      })),
    };

    const observable = this.isCreationMode
      ? this.data.collection('Dashboards').insert(dashboard)
      : this.data
          .collection('Dashboards')
          .entity(this.dashboard.id)
          .update(dashboard);

    observable.subscribe({
      next: async (data: Dashboard) => {
        if (this.isCreationMode) {
          this.notification.successLocal(
            'analytics.dashboards.settings.messages.dashboardHasBeenCreated',
          );

          await this.navigationService.reloadCustomNavigationItems();

          this.state.go('dashboard', {
            entityId: data.id,
            navigation: `analytics.${data.id}`,
          });
        } else {
          this.notification.successLocal(
            'analytics.dashboards.settings.messages.dashboardHasBeenSaved',
          );
        }

        this.isSaving$.next(false);
        this.activeModal.close();
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving$.next(false);
      },
    });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  private load() {
    this.isLoading = true;

    const query = {
      expand: {
        owner: { select: ['id', 'name'] },
        grants: {
          select: [
            'id',
            'dashboardId',
            'userId',
            'groupId',
            'permissionSetId',
            'role',
          ],
          expand: {
            user: { select: ['id', 'name'] },
            group: { select: ['id', 'name'] },
            permissionSet: { select: ['id', 'name'] },
          },
        },
      },
    };

    this.data
      .collection('Dashboards')
      .entity(this.dashboard.id)
      .get<Dashboard>(query)
      .subscribe({
        next: (data) => {
          this.dashboard = data;

          this.configuration = JSON.parse(this.dashboard.configuration);

          if (this.configuration.defaultViewPeriod?.PeriodType) {
            // TODO Совместимость
            this.configuration.defaultViewPeriod = {
              from: this.configuration.defaultViewPeriod.From,
              to: this.configuration.defaultViewPeriod.To,
              periodType: this.configuration.defaultViewPeriod.PeriodType,
            };

            if (
              this.configuration.defaultViewPeriod.periodType !==
              DatePeriodType.Custom
            ) {
              const pair = this.dateService.getDatePair(
                this.configuration.defaultViewPeriod.periodType,
              );
              this.configuration.defaultViewPeriod.from = pair.from;
              this.configuration.defaultViewPeriod.to = pair.to;
            }
          }
          this.navigationResponse = this.dashboard.navigations.sort(
            naturalSort('area'),
          );
          this.form.patchValue(this.dashboard);
          this.form.patchValue(this.configuration);

          this.dashboard.grants.forEach((grant) => {
            const group =
              this.dashboardSettingsService.getPerformerFormGroup(grant);
            this.permissions.push(group);
          });

          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  /** Validates navigations before saving. */
  private validateNavigations(): void {
    this.navigations.controls.forEach((formGroup: FormGroup) => {
      if (!formGroup.value.area) {
        formGroup.get('area').setValidators(Validators.required);
        formGroup.get('area').updateValueAndValidity();
      }
      if (!formGroup.value.group && formGroup.get('group').enabled) {
        formGroup.get('group').setValidators(Validators.required);
        formGroup.get('group').updateValueAndValidity();
      }
    });
  }
}
