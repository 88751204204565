import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { UntypedFormControl } from '@angular/forms';
import { ClientContactsService } from 'src/app/clients/card/client-contact/core/client-contacts.service';

@Component({
  selector: 'tmt-client-contacts-toolbar',
  templateUrl: './client-contacts-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientContactsToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  public filterControl = new UntypedFormControl(false);

  constructor(
    gridService: GridService,
    cdr: ChangeDetectorRef,
    private clientContactsService: ClientContactsService,
  ) {
    super(gridService, cdr);

    this.filterControl.setValue(this.clientContactsService.onlyActive());

    this.filterControl.valueChanges.subscribe(() => {
      this.clientContactsService.onlyActive.set(this.filterControl.value);
      this.clientContactsService.load();
    });
  }
}
