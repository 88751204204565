import {
  Component,
  OnInit,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { UntypedFormGroup } from '@angular/forms';
import { ContactCreateModalComponent } from 'src/app/shared/components/contact-create/contact-create.component';

@Component({
  selector: 'tmt-contacts-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private entityListService: EntityListService,
    private gridService: GridService,
    private stateService: StateService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'components.contactsListComponent.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'Contact',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('contact', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: UntypedFormGroup[]) => {
        this.actionPanelService.action('card').isShown = rows?.length === 1;
      });
  }

  private create(): void {
    this.modalService.open(ContactCreateModalComponent);
  }
}
