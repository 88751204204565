import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortableEvent, SortableOptions } from 'sortablejs';

import _ from 'lodash';

import { NotificationService } from 'src/app/core/notification.service';

import { RateMatrixService } from '../rate-matrix.service';
import {
  Analytics,
  RateMatrixStructureItem,
  analytics,
} from './rate-matrix-structure.model';

@Component({
  selector: 'tmt-rate-matrix-structure-change-modal',
  templateUrl: './rate-matrix-structure-change-modal.component.html',
  styleUrls: ['./rate-matrix-structure-change-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RateMatrixStructureChangeModalComponent implements OnInit {
  @Input() public rateMatrixStructure: Analytics[];
  @Input() public readonly: boolean;

  public analytics: RateMatrixStructureItem[] = [];
  public selectedAnalytics: RateMatrixStructureItem[] = [];
  public analyticsDepends: Partial<Record<Analytics, Analytics>> = {
    competence: 'role',
    grade: 'level',
  };
  public analyticsSortableOptions: SortableOptions = {
    group: {
      name: 'analytics',
    },
    sort: false,
    handle: '.handle',
    draggable: '.draggable',
    animation: 0,
    onStart: () => {
      this.cdr.markForCheck();
    },
    onEnd: () => {
      this.cdr.markForCheck();
    },
  };
  public selectedColumnsSortableOptions: SortableOptions = {
    group: {
      name: 'analytics',
    },
    handle: '.handle',
    draggable: '.draggable',
    chosenClass: 'chosen-selected',
    ghostClass: 'ghost-selected',
    dragClass: 'drag-selected',
    onStart: () => {
      this.cdr.markForCheck();
    },
    onEnd: () => {
      this.cdr.markForCheck();
    },
    onSort: (event: SortableEvent) => {
      setTimeout(() => {
        this.checkOrder(event.item.dataset.key as Analytics);
        this.checkDifference();
      });
    },
  };

  constructor(
    private rateMatrixService: RateMatrixService,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  public ngOnInit(): void {
    this.init();
  }

  /**
   * Removes analytic from selected.
   *
   * @param analytic Selected analytic.
   */
  public removeSelected(analytic: RateMatrixStructureItem): void {
    this.analytics = this.analytics.concat(
      _.remove(this.selectedAnalytics, (el) => el.key === analytic.key),
    );
    this.checkDifference();
    this.cdr.markForCheck();
  }

  /** Sends new rateMatrixStructure to settings. */
  public ok(): void {
    if (!this.selectedAnalytics.length) {
      this.notificationService.warningLocal(
        'settings.rateMatrices.changeStructure.validation',
      );

      return;
    }

    this.closeModal(true);
  }

  /**
   * Closes modal.
   *
   * @param withResult If `true`, closes with result.
   */
  public closeModal(withResult?: boolean): void {
    if (withResult) {
      this.activeModal.close(
        this.selectedAnalytics.map((analytic) => _.upperFirst(analytic.key)),
      );

      return;
    }

    this.activeModal.dismiss();
  }

  private checkOrder(key: Analytics): void {
    for (const dependedAnalytic of Object.keys(this.analyticsDepends)) {
      const analyticIndex = this.selectedAnalytics.findIndex(
        (item) => item.key === this.analyticsDepends[dependedAnalytic],
      );
      const dependedAnalyticIndex = this.selectedAnalytics.findIndex(
        (item) => item.key === dependedAnalytic,
      );

      if (dependedAnalyticIndex === -1) {
        continue;
      }

      if (key === dependedAnalytic && analyticIndex > dependedAnalyticIndex) {
        const element = this.selectedAnalytics.splice(analyticIndex, 1)[0];
        const newDependedAnalyticIndex = this.selectedAnalytics.findIndex(
          (item) => item.key === dependedAnalytic,
        );
        this.selectedAnalytics.splice(newDependedAnalyticIndex, 0, element);

        continue;
      }

      if (analyticIndex > dependedAnalyticIndex) {
        const element = this.selectedAnalytics.splice(
          dependedAnalyticIndex,
          1,
        )[0];
        const newAnalyticIndex = this.selectedAnalytics.findIndex(
          (item) => item.key === this.analyticsDepends[dependedAnalytic],
        );
        this.selectedAnalytics.splice(newAnalyticIndex + 1, 0, element);

        continue;
      }
    }

    this.cdr.detectChanges();
  }

  private checkDifference(): void {
    if (!this.rateMatrixStructure.length) {
      return;
    }

    let itemsToRemove: Analytics[] = [];

    for (
      let i = 0;
      i <= this.rateMatrixService.rateMatrixStructure.length;
      i++
    ) {
      if (
        this.rateMatrixService.rateMatrixStructure[i] !==
        this.selectedAnalytics[i]?.key
      ) {
        itemsToRemove = this.rateMatrixService.rateMatrixStructure.slice(i);
        break;
      }
    }

    if (itemsToRemove.length) {
      this.rateMatrixService.changeStructureMessage =
        this.translateService.instant(
          `settings.rateMatrices.changeStructure.changeStructureMessage`,
          {
            analytics: itemsToRemove
              .map((item) =>
                this.translateService.instant(
                  `shared.rateMatrices.${_.camelCase(item)}`,
                ),
              )
              .join(', '),
          },
        );
    } else {
      this.rateMatrixService.changeStructureMessage = null;
    }
  }

  private init(): void {
    this.analytics = analytics
      .filter((analytic) => analytic !== 'kind') //TODO: remove when backend ready.
      .map((key) => ({
        key,
        title: this.translateService.instant(`shared.rateMatrices.${key}`),
        hint: null,
        disabled: this.readonly,
      }));

    this.rateMatrixStructure
      .map((analytic) => _.camelCase(analytic))
      .forEach((analytic) => {
        const selectedAnalytic = _.remove(
          this.analytics,
          (el) => el.key === analytic,
        );

        this.selectedAnalytics =
          this.selectedAnalytics.concat(selectedAnalytic);
      });
  }
}
