@if (fields.length && isVisibleHr) {
  <div class="custom-field-separator">
    <hr />
  </div>
}

<div class="row">
  <div [ngClass]="columnClasses" data-test="leftGroup">
    @for (field of leftFields; track field) {
      <div class="form-group" tmtIndicator>
        <label>{{ field.viewConfiguration.label }}</label>
        <wp-custom-field
          #controlWrapper
          [field]="field"
          [control]="getControl(field)"
          [mode]="mode"
          [attr.data-test-name]="field.name"
        ></wp-custom-field>
      </div>
    }
  </div>
  <div [ngClass]="columnClasses" data-test="rightGroup">
    @for (field of rightFields; track field) {
      <div class="form-group" tmtIndicator>
        <label>{{ field.viewConfiguration.label }}</label>
        <wp-custom-field
          #controlWrapper
          [field]="field"
          [control]="getControl(field)"
          [mode]="mode"
          [attr.data-test-name]="field.name"
        ></wp-custom-field>
      </div>
    }
  </div>
</div>
