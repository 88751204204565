import { Injectable, Injector } from '@angular/core';

import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { TransitionService } from '@uirouter/core';

import { BehaviorSubject } from 'rxjs';

import { OffCanvasEntry } from '../shared/models/off-canvas/off-canvas-entry.interface';
import { OffCanvasComponent } from '../shared/components/features/off-canvas/off-canvas.component';

@Injectable({
  providedIn: 'root',
})
export class OffCanvasService {
  public offCanvasEntry: OffCanvasEntry;
  private entityUpdatedSubject = new BehaviorSubject<unknown>(null);
  public entityUpdated$ = this.entityUpdatedSubject.asObservable();

  private offCanvasWidthSubject = new BehaviorSubject<number>(null);
  public offCanvasWidth$ = this.offCanvasWidthSubject.asObservable();

  private offCanvas: NgbOffcanvasRef;

  constructor(
    private injector: Injector,
    private bootstrapOffCanvasService: NgbOffcanvas,
    transitionService: TransitionService,
  ) {
    transitionService.onSuccess({}, () => {
      this.closeOffCanvas();
    });
  }

  /**
   * Inits entry and opens off canvas.
   *
   * @param entityId id of needed entity.
   * @param entry off canvas content config.
   * @param animation off canvas animation property.
   */

  public openOffCanvas<T>(
    entityId: string,
    entry: OffCanvasEntry<T>,
    animation = true,
  ): void {
    this.offCanvasEntry = entry;
    const injector = Injector.create({
      providers: [
        {
          provide: 'entityId',
          useValue: entityId,
        },
      ],
      parent: this.injector,
    });
    this.offCanvas = this.bootstrapOffCanvasService.open(OffCanvasComponent, {
      position: 'end',
      backdrop: false,
      injector,
      panelClass: 'active-task-panel',
      scroll: true,
      animation,
    });
  }

  /**
   * Closes offCanvas and clears its entry.
   * Also waits for the result before closing if the entry has an `onClose` callback.
   */
  public async closeOffCanvas(): Promise<void> {
    if (!this.offCanvas) {
      return;
    }

    if (!this.offCanvasEntry?.onClose) {
      this.dismiss();
      return;
    }

    this.offCanvasEntry.onClose().then(
      () => this.dismiss(),
      () => null,
    );
  }

  /**
   * Emits event on entity update.
   *
   * @param entity updated entity.
   */
  public onEntityUpdated(entity: unknown): void {
    this.entityUpdatedSubject.next(entity);
  }

  /**
   * Sets offCanvas wrapper width.
   *
   * @param width current width.
   */
  public setWidth(width: number): void {
    this.offCanvasWidthSubject.next(width);
  }

  /**
   * Checks is the component of offCanvas entry an instance of the component.
   *
   * @param component Any component.
   * @returns `true` if it is, otherwise `false`.
   */
  public isEntryEqualComponent(component: object): boolean {
    return !!(
      this.offCanvasEntry?.content.component &&
      component instanceof this.offCanvasEntry.content.component
    );
  }

  private dismiss(): void {
    this.offCanvas.dismiss('close');
    this.offCanvasEntry = null;
  }
}
