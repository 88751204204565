import { NavigationService } from '../core/navigation.service';
import { DebugAppComponent } from './debug-app.component';
import { DEBUG_APP_NAVIGATION } from './debug-app.navigation';
import { StatusPageComponent } from './status-page/status-page.component';
import { HierarchicalBoxTestComponent } from './hierarchical-box-test/hierarchical-box-test.component';
import { TextBoxTestComponent } from './text-box-test/text-box-test.component';
import { UserBoxTestComponent } from './user-box-test/user-box-test.component';
import { NumberBoxTestComponent } from './number-box-test/number-box-test.component';
import { MultilineTextBoxTestComponent } from './multiline-text-box-test/multiline-text-box-test.component';
import { SelectBoxTestComponent } from './select-box-test/select-box-test.component';
import { DateBoxTestComponent } from './date-box-test/date-box-test.component';
import { DatePeriodBoxTestComponent } from './date-period-box-test/date-period-box-test.component';
import { TaskBoxTestComponent } from './task-box-test/task-box-test.component';
import { CurrencyBoxTestComponent } from './currency-box-test/currency-box-test.component';
import { GridTestComponent } from './grid-test/grid-test.component';
import { DurationBoxTestComponent } from 'src/app/debug-app/duration-box-test/duration-box-test.component';
import { PredecessorBoxTestComponent } from 'src/app/debug-app/predecessor-box-test/predecessor-box-test.component';
import { TreeListTestComponent } from 'src/app/debug-app/tree-list-test/tree-list-test.component';
import { Grid2TestComponent } from 'src/app/debug-app/grid2-test/grid2-test.component';
import { FormHelperTestComponent } from 'src/app/debug-app/form-helper-test/form-helper-test.component';
import { RichEditorBoxTestComponent } from 'src/app/debug-app/rich-editor-box-test/rich-editor-box-test.component';

const appStates: any[] = [];

export function initApp(navigation: NavigationService) {
  navigation.load(DEBUG_APP_NAVIGATION);
}

appStates.push({
  name: 'debug',
  url: '/debug',
  component: DebugAppComponent,
  redirectTo: 'debug.status',
  resolve: [
    {
      token: 'debugAppInitializer',
      deps: [NavigationService],
      resolveFn: initApp,
    },
  ],
});

appStates.push({
  name: 'debug.status',
  url: '/status',
  component: StatusPageComponent,
});

appStates.push({
  name: 'debug.textBox',
  url: '/controls/textBox',
  component: TextBoxTestComponent,
});

appStates.push({
  name: 'debug.multilineTextBox',
  url: '/controls/multilineTextBox',
  component: MultilineTextBoxTestComponent,
});

appStates.push({
  name: 'debug.numberBox',
  url: '/controls/numberBox',
  component: NumberBoxTestComponent,
});

appStates.push({
  name: 'debug.selectBox',
  url: '/controls/selectBox',
  component: SelectBoxTestComponent,
});

appStates.push({
  name: 'debug.richEditorBox',
  url: '/controls/richEditorBox',
  component: RichEditorBoxTestComponent,
});

appStates.push({
  name: 'debug.userBox',
  url: '/controls/userBox',
  component: UserBoxTestComponent,
});

appStates.push({
  name: 'debug.hierarchical',
  url: '/controls/hierarchicalBox',
  component: HierarchicalBoxTestComponent,
});

appStates.push({
  name: 'debug.task',
  url: '/controls/taskBox',
  component: TaskBoxTestComponent,
});

appStates.push({
  name: 'debug.dateBox',
  url: '/controls/dateBox',
  component: DateBoxTestComponent,
});

appStates.push({
  name: 'debug.datePeriodBox',
  url: '/controls/datePeriodBox',
  component: DatePeriodBoxTestComponent,
});

appStates.push({
  name: 'debug.currencyBox',
  url: '/controls/currencyBox',
  component: CurrencyBoxTestComponent,
});

appStates.push({
  name: 'debug.grid',
  url: '/grid',
  component: GridTestComponent,
});

appStates.push({
  name: 'debug.grid2',
  url: '/grid2',
  component: Grid2TestComponent,
});

appStates.push({
  name: 'debug.duration',
  url: '/duration',
  component: DurationBoxTestComponent,
});

appStates.push({
  name: 'debug.formHelper',
  url: '/formHelper',
  component: FormHelperTestComponent,
});

appStates.push({
  name: 'debug.multiSelect',
  url: '/multiSelect',
  component: PredecessorBoxTestComponent,
  resolve: [
    {
      token: 'entityId',
      deps: [],
      resolveFn: () => 0,
    },
  ],
});

appStates.push({
  name: 'debug.tree-list',
  url: '/tree-list',
  component: TreeListTestComponent,
});

export const DEBUG_APP_STATES = appStates;
