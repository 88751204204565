<form class="modal-form" novalidate [formGroup]="creationForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.createContactModalComponent.props.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving()"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">{{ 'shared2.props.firstName' | translate }}</label>
      <wp-text-box formControlName="firstName" placeholder="{{ 'shared2.props.firstName' | translate }}" />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.lastName' | translate }}</label>
      <wp-text-box formControlName="lastName" placeholder="{{ 'shared2.props.lastName' | translate }}" />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.patronymic' | translate }}</label>
      <wp-text-box formControlName="patronymic" placeholder="{{ 'shared2.props.patronymic' | translate }}" />
    </div>

    @if (!organizationId) {
      <div class="form-group">
        <label class="control-label">{{ 'shared2.props.organization' | translate }}</label>
        <wp-select-box
          formControlName="organizationId"
          collection="Organizations"
          [isIdMode]="true"
          placeholder="{{ 'shared2.props.organization' | translate }}"
        />
      </div>
    }

    <div class="form-group">
      <label class="control-label">{{ 'shared2.props.role' | translate }}</label>
      <wp-select-box
        formControlName="roleId"
        [isIdMode]="true"
        [directoryId]="systemDirectory.contactRole"
        placeholder="{{ 'shared2.props.role' | translate }}"
      />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.position' | translate }}</label>
      <wp-text-box formControlName="position" placeholder="{{ 'shared2.props.position' | translate }}" />
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving()"
    >
      {{ 'shared2.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving()">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</form>
