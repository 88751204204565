import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { PerformersService } from 'src/app/settings-app/shared/performers/performers.service';

@Component({
  selector: 'tmt-issue-type-permissions-performers',
  templateUrl: './performers.component.html',
  providers: [PerformersService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueTypePermissionsPerformersComponent {
  @Input() readonly: boolean;
  @Input() lines: FormArray;
  @Input() gridHeader = '';

  constructor(public performersService: PerformersService) {}
}
