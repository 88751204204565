import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ClientContactsService } from 'src/app/clients/card/client-contact/core/client-contacts.service';
import { CLIENT_CONTACTS } from 'src/app/clients/card/client-contact/model/client-contacts.list';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-client-contacts',
  templateUrl: './client-contacts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ClientContactsService,
    SavingQueueService,
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: CLIENT_CONTACTS },
    ListService,
    GridService,
  ],
})
export class ClientContactsComponent {
  private destroyRef = inject(DestroyRef);

  constructor(
    public clientContactsService: ClientContactsService,
    private service: ClientCardService,
  ) {
    this.clientContactsService.load();

    this.service.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.clientContactsService.load());
  }
}
