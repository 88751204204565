<div class="toolbar-container">
  @if (!gridService.readonly) {
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared2.actions.addLine' | translate }}"
      (click)="gridService.execute('addLine')"
    >
      <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
      {{ 'shared2.actions.addLine' | translate }}
    </button>
  }
</div>
