import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { List } from 'src/app/shared/models/inner/list';
import {
  GridColumnType,
  GridSelectControlColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { SystemDirectory } from 'src/app/shared/models/enums/system-directory.enum';

export const CLIENT_CONTACTS: List = {
  name: 'clientContacts',
  version: 1,
  // TODO: uncomment after adding custom fields
  // customFieldEntityType: 'ClientContacts',
  // editableCustomFields: true,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'firstName',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'lastName',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'roleId',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'position',
          visibleByDefault: true,
          width: 90,
        },
        {
          column: 'description',
          width: 250,
          visibleByDefault: false,
        },
        {
          column: 'email',
          width: 250,
          visibleByDefault: false,
        },
        {
          column: 'mobilePhone',
          visibleByDefault: false,
          width: 250,
        },
        {
          column: 'phone',
          visibleByDefault: false,
          width: 250,
        },
      ],
    },
  ],
  columns: [
    <GridStringControlColumn>{
      name: 'firstName',
      type: GridColumnType.StringControl,
      header: 'shared2.props.firstName',
      hint: 'shared2.props.firstName',
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'lastName',
      type: GridColumnType.StringControl,
      header: 'shared2.props.lastName',
      hint: 'shared2.props.lastName',
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridSelectControlColumn>{
      name: 'roleId',
      type: GridColumnType.SelectControl,
      isIdMode: true,
      header: 'shared2.props.role',
      hint: 'shared2.props.role',
      directoryId: SystemDirectory.contactRole,
    },
    <GridStringControlColumn>{
      name: 'position',
      header: 'shared2.props.position',
      hint: 'shared2.props.position',
      type: GridColumnType.StringControl,
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'description',
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
      fixedWidth: true,
      type: GridColumnType.StringControl,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'email',
      header: 'shared2.props.email',
      hint: 'shared2.props.email',
      fixedWidth: true,
      type: GridColumnType.StringControl,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'mobilePhone',
      header: 'shared2.props.mobilePhone',
      hint: 'shared2.props.mobilePhone',
      type: GridColumnType.StringControl,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridStringControlColumn>{
      name: 'phone',
      header: 'shared2.props.phone',
      hint: 'shared2.props.phone',
      type: GridColumnType.StringControl,
      propagationMode: PropagationMode.onExitFromEditing,
    },
  ],
  dataColumns: [],
};
