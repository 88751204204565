import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import {
  AVAILABLE_DEPENDENCY_TYPES,
  PROJECT_TASK_TYPES,
  ProjectTask,
  ProjectTaskDependency,
  ProjectTaskDependencyType,
  ProjectTaskType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  GridColumnType,
  GridSelectControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { merge, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { difference } from 'lodash';
import { ScheduleService } from 'src/app/core/schedule.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wp-task-card-modal',
  templateUrl: './task-card-modal.component.html',
  styleUrls: ['./task-card-modal.component.scss'],
  providers: [GridService],
})
export class TaskCardModalComponent implements OnInit, OnDestroy {
  @Input() task: ProjectTask;
  @Input() projectVersion: ProjectVersion;
  @Input() readonly: boolean;
  @Input() isInherited: boolean;
  @Input() autoPlanning: boolean;

  public feature = Feature;

  public propagationMode = PropagationMode;

  public taskTypes: NamedEntity[] = [];

  public form: UntypedFormGroup;
  public dependencies: UntypedFormArray = this.fb.array([]);
  public gridOptions: GridOptions;
  public isSummaryTask = false;

  public readonly constants = Constants;

  private dependenciesChangingFromModal = false;
  public dependencyTypes: NamedEntity[];

  private destroyed$ = new Subject<void>();
  private stopSubscriptionsSubject$ = new Subject<void>();

  constructor(
    @Inject('entityId') public projectId,
    public app: AppService,
    private fb: UntypedFormBuilder,
    private projectTasksDataService: ProjectTasksDataService,
    public gridService: GridService,
    private dependenciesService: ProjectTaskDependenciesService,
    public scheduleService: ScheduleService,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {
    this.dependencyTypes = AVAILABLE_DEPENDENCY_TYPES.filter(
      (type) =>
        // Lead task can only has FS or SS dependencies
        !this.projectTasksDataService.checkIsLeadTask(this.task.id) ||
        type === ProjectTaskDependencyType.finishToStart ||
        type === ProjectTaskDependencyType.startToStart,
    ).map((e) => ({
      id: e,
      name: this.translateService.instant(`shared2.props.${e}.value`),
    }));

    const taskFormGroup = this.projectTasksDataService.formArray.controls.find(
      (taskGroup) => taskGroup.value.id === this.task.id,
    ) as UntypedFormGroup;
    if (this.readonly) {
      this.form = _.cloneDeep(taskFormGroup);
      this.form.disable({ emitEvent: false });
    } else {
      this.form = taskFormGroup;
    }

    this.gridOptions = {
      css: 'wp-nested-table',
      sorting: false,
      rowCommands: [
        {
          name: 'delete',
          label: 'shared.actions.delete',
          allowedFn: () => !this.readonly,
          handlerFn: (formGroup: UntypedFormGroup, index: number) => {
            this.dependencies.removeAt(index);
          },
        },
      ],
      view: {
        name: 'default',
        columns: [
          <GridSelectControlColumn>{
            name: 'predecessorControl',
            header: 'components.taskCardModalComponent.props.predecessorTask',
            hint: 'components.taskCardModalComponent.props.predecessorTask',
            placeholder:
              'components.taskCardModalComponent.props.predecessorTask',
            type: GridColumnType.SelectControl,
            values: this.getAvailablePredecessorTasks,
          },
        ],
      },
    };

    const dependencyTypeColumn = <GridSelectControlColumn>{
      name: 'dependencyType',
      header: 'components.taskCardModalComponent.props.dependencyType',
      hint: 'components.taskCardModalComponent.props.dependencyType',
      type: GridColumnType.SelectControl,
      allowNull: false,
      values: this.dependencyTypes,
    };
    //TODO: temporary hide dep type column for production
    if (!environment.production) {
      this.gridOptions.view.columns.push(dependencyTypeColumn);
    }

    this.prepareDependencies();
    this.initExternalChangesSubscription();

    PROJECT_TASK_TYPES.forEach((taskType) =>
      this.taskTypes.push({
        id: taskType,
        name: this.translateService.instant(`enums.taskTypes.${taskType}`),
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Adds new dependency line in the table. */
  public addDependencyLine(): void {
    const group = this.getDependencyFormGroup();
    this.dependencies.insert(0, group);
    this.gridService.selectGroup(group);
  }

  /** Deletes dependency from dependency array. */
  public deleteDependency(): void {
    if (!this.gridService.selectedGroup) {
      return;
    }
    const index = this.dependencies.controls.findIndex(
      (control) => control.value.id === this.gridService.selectedGroup.value.id,
    );
    this.dependencies.removeAt(index);
  }

  /** Closes the modal window. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /** Prepares dependencies form array for table. */
  private prepareDependencies(): void {
    this.task.dependencies.forEach((dependency: ProjectTaskDependency) => {
      const predecessorTask: ProjectTask =
        this.projectTasksDataService.tasks.find(
          (t) => t.id === dependency.predecessorId,
        );
      const predecessorControl: NamedEntity = {
        id: predecessorTask?.id,
        name: `${predecessorTask?.structNumber} ${predecessorTask?.name}`,
      };
      const dependencyType = this.dependencyTypes.find(
        (t) => t.id === dependency.type,
      );

      const group = this.getDependencyFormGroup();
      group.patchValue(
        { predecessorControl, dependencyType },
        { emitEvent: false },
      );

      this.dependencies.push(group);
    });

    this.dependencies.valueChanges
      .pipe(
        map((newDependencies) => {
          newDependencies = newDependencies.filter(
            (d) => d.predecessorControl?.id,
          );
          return newDependencies.map((d) => ({
            type: d.dependencyType.id,
            predecessorId: d.predecessorControl.id,
          }));
        }),
        filter(
          (newDependencies) =>
            !_.isEqual(this.form.controls.dependencies.value, newDependencies),
        ),
        takeUntil(merge(this.destroyed$, this.stopSubscriptionsSubject$)),
      )
      .subscribe((newDependencies) => {
        this.dependenciesChangingFromModal = true;
        this.form.controls.dependencies.setValue(newDependencies);
      });
  }

  /** Returns dependency form group for table.
   *
   * @returns dependency form group
   */
  private getDependencyFormGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      dependencyType: [this.dependencyTypes[0], Validators.required],
      predecessorControl: [null, Validators.required],
    });

    return group;
  }

  /** Returns available predecessor tasks for new dependency.
   *
   * @returns available predecessor tasks in NamedEntity list format
   */
  private getAvailablePredecessorTasks = (): NamedEntity[] => {
    const type = this.gridService.selectedGroup.value.dependencyType.id;
    const allowedTaskIds = this.dependenciesService
      .getAllowedPredecessorTaskNodes(this.task.id, type)
      .map((node) => node.id);

    // Current predecessor task ids (the project task card level, not saved)
    const existPredecessorIds = this.dependencies.value
      .filter((d) => d.dependencyType.id === type)
      .flatMap((dependency) => dependency.predecessorControl?.id)
      .filter((id) => !!id);
    const availablePredecessorTaskIds = difference(
      allowedTaskIds,
      existPredecessorIds,
    );
    return this.projectTasksDataService.tasks
      .filter((task) => availablePredecessorTaskIds.includes(task.id))
      .map((task) => ({
        id: task.id,
        name: `${task.structNumber} ${task.name}`,
      }));
  };

  /** Inits listener of main formArray dependencies changing. */
  private initExternalChangesSubscription() {
    if (!this.readonly) {
      this.form.controls.dependencies.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          if (this.dependenciesChangingFromModal) {
            this.dependenciesChangingFromModal = false;
          } else {
            const newValueStringArray = value.map(
              (dependency) => dependency.predecessorId + dependency.type,
            );
            const existValueStringArray = this.dependencies.value
              .filter((d) => d.predecessorControl)
              .map(
                (dependency) =>
                  dependency.predecessorControl?.id +
                  dependency.dependencyType.id,
              );
            const isEqual = _.isEqual(
              newValueStringArray,
              existValueStringArray,
            );

            if (!isEqual) {
              this.rebuildDependencies();
            }
          }
        });
    }
  }

  /** Rebuilds dependencies table. */
  private rebuildDependencies() {
    this.stopSubscriptionsSubject$.next();
    this.dependencies.clear();
    this.prepareDependencies();
  }
}
