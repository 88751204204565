<tmt-form-header [name]="issueTypeCardService.name$ | async" />
<tmt-not-found [state]="issueTypeCardService.state$ | async" />
<wp-loading-indicator [state]="issueTypeCardService.state$ | async" />
<wp-data-not-saved-indicator [form]="issueTypeCardService.form" />

@if ((issueTypeCardService.state$ | async) == 'Ready') {
  <form [formGroup]="issueTypeCardService.form" class="form mt-3">
    @if ((issueTypeCardService.issueType$ | async).isDefault) {
      <p class="fw-semibold">
        <i class="bi bi-check-circle" aria-hidden="true"></i> &nbsp;{{
          'components.issueTypeCardComponent.messages.usingByDefault' | translate
        }}
      </p>
    }

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
    </div>

    <div
      class="form-group"
      tmt-icon-picker
      [control]="issueTypeCardService.form.get('iconClass')"
    ></div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared.props.code' | translate }}</label>
      <p class="form-control-plaintext">
        {{ (issueTypeCardService.issueType$ | async).code }}
      </p>
    </div>

    <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>

    <tmt-issue-type-permissions
      [entityId]="issueTypeCardService.entityId"
      [readonly]="readonly"
      [team]="issueTypeCardService.members"
      [isSaving$]="issueTypeCardService.isSaving$"
    />

    @if (!readonly) {
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          wpButtonSpinner
          [isBusy]="issueTypeCardService.isSaving$ | async"
          (click)="issueTypeCardService.save(detectPerformersChanges)"
        >
          {{ 'shared.actions.save' | translate }}
        </button>
      </div>
    }
  </form>
}
