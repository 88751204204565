import { Navigation } from '../shared/models/navigation/navigation';

export const DEBUG_APP_NAVIGATION: Navigation = {
  name: 'admin',
  applicationTitle: 'Debug',
  groups: [
    {
      name: 'admin',
      header: 'Admin',
      items: [
        {
          name: 'debug.status',
          state: 'debug.status',
          header: 'Status',
          hint: 'Status page',
        },
      ],
    },
    {
      name: 'controls',
      header: 'Controls',
      items: [
        {
          name: 'debug.textBox',
          state: 'debug.textBox',
          header: 'TextBox',
          hint: 'TextBox',
        },
        {
          name: 'debug.multilineTextBox',
          state: 'debug.multilineTextBox',
          header: 'MultilineTextBox',
          hint: 'MultilineTextBox',
        },
        {
          name: 'debug.numberBox',
          state: 'debug.numberBox',
          header: 'NumberBox',
          hint: 'NumberBox',
        },
        {
          name: 'debug.selectBox',
          state: 'debug.selectBox',
          header: 'SelectBox',
          hint: 'SelectBox',
        },
        {
          name: 'debug.richEditorBox',
          state: 'debug.richEditorBox',
          header: 'RichEditorBox',
          hint: 'RichEditorBox',
        },
        {
          name: 'debug.userBox',
          state: 'debug.userBox',
          header: 'UserBox',
          hint: 'UserBox',
        },
        {
          name: 'debug.hierarchical',
          state: 'debug.hierarchical',
          header: 'HierarchicalBox',
          hint: 'HierarchicalBox',
        },
        {
          name: 'debug.task',
          state: 'debug.task',
          header: 'TaskBox',
          hint: 'TaskBox',
        },
        {
          name: 'debug.dateBox',
          state: 'debug.dateBox',
          header: 'DateBox',
          hint: 'DateBox',
        },
        {
          name: 'debug.datePeriodBox',
          state: 'debug.datePeriodBox',
          header: 'DatePeriodBox',
          hint: 'DatePeriodBox',
        },
        {
          name: 'debug.currencyBox',
          state: 'debug.currencyBox',
          header: 'CurrencyBox',
          hint: 'CurrencyBox',
        },
        {
          name: 'debug.grid',
          state: 'debug.grid',
          header: 'Grid',
          hint: 'Grid',
        },
        {
          name: 'debug.grid2',
          state: 'debug.grid2',
          header: 'Grid2',
          hint: 'Grid2',
        },
        {
          name: 'debug.duration',
          state: 'debug.duration',
          header: 'DurationBox',
          hint: 'DurationBox',
        },
        {
          name: 'debug.multiSelect',
          state: 'debug.multiSelect',
          header: 'MultiSelect Box',
          hint: 'MultiSelect Box',
        },
        {
          name: 'debug.formHelper',
          state: 'debug.formHelper',
          header: 'Form Helper',
          hint: 'Form Helper',
        },
      ],
    },
    {
      name: 'shared',
      header: 'Shared',
      items: [
        {
          name: 'debug.tree-list',
          state: 'debug.tree-list',
          header: 'Tree list',
          hint: 'Tree list',
        },
      ],
    },
  ],
};
