import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { SystemDirectory } from 'src/app/shared/models/enums/system-directory.enum';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'tmt-contact-create',
  templateUrl: './contact-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class ContactCreateModalComponent {
  @Input() organizationId: string | undefined;

  public isSaving = signal<boolean>(false);
  public creationForm: UntypedFormGroup = this.fb.group({
    firstName: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    lastName: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    patronymic: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    organizationId: null,
    roleId: null,
    position: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
  });
  public readonly systemDirectory = SystemDirectory;

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Modal submit handler. */
  public ok(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    const data = {
      firstName: this.creationForm.value.firstName,
      lastName: this.creationForm.value.lastName,
      name: `${this.creationForm.value.firstName} ${this.creationForm.value.lastName}`,
      patronymic: this.creationForm.value.patronymic,
      organizationId:
        this.organizationId ?? this.creationForm.value.organizationId,
      position: this.creationForm.value.position,
      roleId: this.creationForm.value.roleId,
    };

    this.dataService
      .collection('Contacts')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.createContactModalComponent.messages.created',
          );

          if (!this.organizationId) {
            this.stateService.go('contact', {
              entityId: response.id,
            });
          }
          this.isSaving.set(false);
          this.activeModal.close(response);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Modal cancel handler. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
