import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Command } from 'src/app/shared/components/features/grid/grid-options.model';
import { AppService } from 'src/app/core/app.service';
import {
  TimeAllocation,
  TimeOffRequest,
  TimesheetLine,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { TranslateService } from '@ngx-translate/core';
import { TimesheetCardService } from '../core/timesheet-card.service';
import { assign, cloneDeep, sumBy } from 'lodash';
import { TimesheetTemplate } from 'src/app/shared/models/entities/settings/timesheet-template.model';
import { Day } from '../shared/models/day.model';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { Line } from '../shared/models/line.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { Task } from '../shared/models/task.model';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { NotificationService } from 'src/app/core/notification.service';
import {
  map,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NavigationService } from 'src/app/core/navigation.service';
import { RoleService } from '../core/role.service';
import { DateTime } from 'luxon';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { CostCenterService } from '../core/cost-center.service';
import { TariffService } from '../core/tariff.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';
import { TimeSheetService } from 'src/app/shared/services/timesheet.service';

/** Табличное представление таймшита. */
@Component({
  selector: 'wp-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  providers: [
    CellsOrchestratorService,
    CostCenterService,
    TariffService,
    RoleService,
    TimeSheetService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableViewComponent implements OnInit, OnDestroy {
  // Constants.
  public readonly columnsWidths: Record<string, number> = {
    taskColumnWidth: 335,
    checkBoxColumnWidth: 40,
    roleRateColumnWidth: 110,
    activityColumnWidth: 110,
    customColumnWidth: 110,
    projectCostCenterColumnWidth: 110,
    projectTariffColumnWidth: 110,
  };

  public readonly: boolean;
  public hasLinesInStorage$ = new BehaviorSubject<boolean>(false);

  get template(): TimesheetTemplate {
    return this.service.timesheet?.template;
  }
  get timeOffRequests(): TimeOffRequest[] {
    return this.service.timesheet?.timeOffRequests;
  }

  public addingCommands: Command[];
  public fixTableColumnCount = 0;
  public fixTableWidth: number;
  public dataTableWidth: number;

  public days: Day[] = [];

  /** Form array for data lines. */
  public dataLines = this.fb.array([]);

  public totalHours: number;
  public lineCustomFields: MetaEntityBaseProperty[] = [];

  /** Number of scheduled hours for timesheet. */
  public totalSchedule: number;

  public selectAllControl = new UntypedFormControl(false);
  public selectControls = new UntypedFormArray([]);

  public routeMode = RouteMode;

  private destroyed$ = new Subject<void>();

  constructor(
    public navigationService: NavigationService,
    public service: TimesheetCardService,
    private autosave: SavingQueueService,
    private stopwatchService: StopwatchService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private cellsOrchestrator: CellsOrchestratorService,
    private translate: TranslateService,
    private customFieldService: CustomFieldService,
    private app: AppService,
    private roleService: RoleService,
    private blockUI: BlockUIService,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private costCenterService: CostCenterService,
    private tariffService: TariffService,
  ) {}

  ngOnInit() {
    this.addingCommands = [];

    this.addingCommands.push({
      handlerFn: () => this.copyLines(),
      name: 'copyLines',
      label: 'timesheets.card.actions.copyLines',
    });

    if (this.app.session.configuration.copyHoursAllowed) {
      this.addingCommands.push({
        name: 'copyLinesWithHours',
        handlerFn: () => this.copyLines(true),
        label: 'timesheets.card.actions.copyLinesWithHours',
      });
    }

    this.addingCommands.push({
      name: 'createLinesFromResourcePlan',
      handlerFn: () => this.createLinesFromResourcePlan(),
      label: 'timesheets.card.actions.createLinesFromResourcePlan',
    });

    if (this.app.session.configuration.copyHoursAllowed) {
      this.addingCommands.push({
        name: 'createLinesFromResourcePlanWithHours',
        handlerFn: () => this.createLinesFromResourcePlan(true),
        label: 'timesheets.card.actions.createLinesFromResourcePlanWithHours',
      });
    }

    if (
      this.app.checkFeature(Feature.timeOff) &&
      this.app.checkEntityPermission('TimeOffRequest', PermissionType.Modify)
    ) {
      this.addingCommands.push({
        name: 'addTimeOffRequest',
        handlerFn: () => this.addTimeOffRequest(),
        label: 'timesheets.card.actions.addTimeOffLine',
      });
    }

    this.service.timesheet$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.lineCustomFields = [];
      const availableCustomFields =
        this.customFieldService.getList('TimeSheetLine');
      this.template.customFields.forEach((fieldInTemplate) => {
        const field = availableCustomFields.find(
          (f) => f.customFieldId === fieldInTemplate.customFieldId,
        );
        if (field) {
          this.lineCustomFields.push(field);
        }
      });

      this.fillOutDays();
      this.calculateWidth();

      this.fillOutDataLines();
      if (!this.service.timesheet.editAllowed) {
        this.dataLines.disable({ emitEvent: false });
      }

      this.readonly = !this.service.timesheet.editAllowed;
      this.cellsOrchestrator.init();

      this.calculateTotals();
    });

    this.selectAllControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const value = this.selectAllControl.value as boolean;
        this.selectControls.controls.forEach((control: UntypedFormControl) => {
          control.setValue(value, { emitEvent: false });
        });
      });

    this.selectControls.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const values = this.selectControls.value as boolean[];

        if (values.length === 0 || values.some((v) => !v)) {
          this.selectAllControl.setValue(false, { emitEvent: false });
        }
      });

    this.dataLines.valueChanges
      .pipe(
        startWith(this.dataLines.value),
        pairwise(),
        mergeMap(([previousLines, newLines]: [Line[], Line[]]) => {
          const observables: Observable<any>[] = [];
          newLines?.forEach((newLine, index) => {
            if (!newLine?.task?.project?.id) {
              newLine.role = null;
              newLine.projectCostCenter = null;
              newLine.projectTariff = null;
            } else {
              const previousLine = previousLines.find(
                (l) => l.id === newLine.id,
              );
              const lineGroup = this.dataLines.at(index) as UntypedFormGroup;
              if (previousLine?.task?.project?.id !== newLine.task.project.id) {
                observables.push(
                  this.roleService.getRoles(newLine.task.project.id).pipe(
                    tap((roles) =>
                      this.setControlDefaultValue(
                        lineGroup.controls.role as UntypedFormControl,
                        roles,
                        roles.find((role) => role.fromTeamMember),
                      ),
                    ),
                  ),
                );

                observables.push(
                  this.costCenterService
                    .getProjectCostCenters(newLine.task.project.id)
                    .pipe(
                      tap((costCenters) =>
                        this.setControlDefaultValue(
                          lineGroup.controls
                            .projectCostCenter as UntypedFormControl,
                          costCenters,
                        ),
                      ),
                    ),
                );

                const tariffControl = lineGroup.controls
                  .projectTariff as UntypedFormControl;
                if (
                  newLine.task.project['billingTypeCode'] ===
                  ProjectBillingType.nonBillable.code
                ) {
                  tariffControl.setValue(null);
                  tariffControl.disable();
                } else {
                  tariffControl.enable();

                  observables.push(
                    this.tariffService.getTariffs(newLine.task.project.id).pipe(
                      tap((tariffs) => {
                        this.setControlDefaultValue(
                          tariffControl,
                          tariffs,
                          tariffs.find((tariff) => tariff.isPrimary),
                        );
                      }),
                    ),
                  );
                }
              }
            }
          });

          return observables.length ? forkJoin(observables) : of([]);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.calculateTotals();
        this.service.changeData(this.dataLines.value as Line[]);
        this.changeDetector.markForCheck();
      });

    this.hasLinesInStorage$.next(
      this.localStorageService.retrieve(this.service.timesheetLinesStorageName)
        ?.length > 0,
    );
  }

  /**
   * Sets default value for line's control.
   *
   * @param control line's needed control.
   * @param options line's column select options.
   * @param defaultOption line's column default option.
   */
  private setControlDefaultValue(
    control: UntypedFormControl,
    options: NamedEntity[],
    defaultOption?: NamedEntity,
  ): void {
    const defaultValue =
      defaultOption || (options?.length === 1 ? options[0] : null);
    if (control.value) {
      control.setValue(
        options.find((option) => option.id === control.value.id) ??
          defaultValue,
      );
    } else {
      control.setValue(defaultValue);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.cellsOrchestrator.dispose();
  }

  private lineHasData(dataLine: UntypedFormGroup): boolean {
    const allocations = dataLine.controls['allocations']
      .value as TimeAllocation[];

    return allocations.some(
      (allocation) =>
        allocation.duration > 0 ||
        allocation.comments ||
        !this.customFieldService
          .getList('TimeAllocation')
          .every((field) => allocation[field.name] == null),
    );
  }

  public hasEmptyLines(): boolean {
    return !this.dataLines.controls.every((dataLine: UntypedFormGroup) =>
      this.lineHasData(dataLine),
    );
  }

  public hasSelectedLines(): boolean {
    const controls = this.selectControls.value as boolean[];
    return controls.some((control) => control);
  }

  /** Deletes selected lines. */
  public removeLines() {
    while (this.hasSelectedLines()) {
      const selectedLines = this.selectControls.value as boolean[];
      const index = selectedLines.findIndex((sl) => sl);

      const needStopStopwatch =
        this.stopwatchService.stopwatch?.timeSheetLineId ===
        this.dataLines.at(index).value.id;
      if (needStopStopwatch) {
        this.notification.warningLocal('timesheets.stopwatchMustBeStopped');
        this.selectControls.at(index).setValue(false);
      } else {
        this.dataLines.removeAt(index);
        this.selectControls.removeAt(index);
      }
    }

    setTimeout(() => this.cellsOrchestrator.reset());
  }

  /** Copies selected lines to the buffer. */
  public copyLinesToStorage() {
    const result: any[] = [];

    this.selectControls.controls.forEach((control, index) => {
      if (control.value) {
        const selectedLine = this.dataLines.at(index);

        if (selectedLine.value.task?.projectTask) {
          const data: any = {};
          this.customFieldService.assignValues(
            data,
            selectedLine.value,
            'TimeSheetLine',
          );
          data.task = cloneDeep(selectedLine.value.task);
          data.activity = cloneDeep(selectedLine.value.activity);
          data.role = cloneDeep(selectedLine.value.role);
          data.projectCostCenter = cloneDeep(
            selectedLine.value.projectCostCenter,
          );
          data.projectTariff = cloneDeep(selectedLine.value.projectTariff);

          result.push(data);
        }
      }
    });

    if (result.length > 0) {
      this.localStorageService.store(
        this.service.timesheetLinesStorageName,
        result,
      );

      this.hasLinesInStorage$.next(true);

      this.notification.successLocal('shared.messages.copiedToClipboard');
    }
  }

  /** Pastes lines from the buffer. */
  public pasteLinesFromStorage() {
    const linesData: any[] = this.localStorageService.retrieve(
      this.service.timesheetLinesStorageName,
    );
    linesData.forEach((lineData) => (lineData.id = Guid.generate()));

    const observables: any = {};

    this.blockUI.start();
    // Проверить возможность вставки данных.
    linesData.forEach((lineData) => {
      observables[lineData.id] = this.service
        .checkIfTaskCanBeUsed(lineData.task)
        .pipe(
          map((taskCanBeUsed) => {
            if (taskCanBeUsed) {
              const line: any = { task: lineData.task };
              this.customFieldService.assignValues(
                line,
                lineData,
                'TimeSheetLine',
              );

              return line;
            } else {
              this.notification.warningLocal(
                'timesheets.card.messages.taskCannotBeUsed',
                { name: (lineData.task as Task).projectTask.name },
              );
              return null;
            }
          }),

          switchMap((line) =>
            forkJoin({
              line: of(line),

              role: new Observable((subscriber) => {
                // Проверить возможность вставки роли.
                if (!line || !lineData.role) {
                  subscriber.next(null);
                  subscriber.complete();
                } else {
                  this.roleService
                    .getRoles(lineData.task.project.id)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((roles) => {
                      if (roles.find((a) => a.id === lineData.role.id)) {
                        subscriber.next({
                          role: lineData.role,
                        });
                      } else {
                        subscriber.next(null);
                      }
                      subscriber.complete();
                    });
                }
              }),

              activity: new Observable((subscriber) => {
                // Проверить возможность вставки вида работ.
                if (!line || !lineData.activity) {
                  subscriber.next(null);
                  subscriber.complete();
                } else {
                  this.service.activities$.subscribe((activities) => {
                    if (activities.find((a) => a.id === lineData.activity.id)) {
                      subscriber.next({ activity: lineData.activity });
                    } else {
                      subscriber.next(null);
                    }
                    subscriber.complete();
                  });
                }
              }),

              projectCostCenter: new Observable((subscriber) => {
                // Paste project cost center.
                if (!line || !lineData.projectCostCenter) {
                  subscriber.next(null);
                  subscriber.complete();
                } else {
                  this.costCenterService
                    .getProjectCostCenters(lineData.task.project.id)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((costCenters) => {
                      if (
                        costCenters.find(
                          (a) => a.id === lineData.projectCostCenter.id,
                        )
                      ) {
                        subscriber.next({
                          projectCostCenter: lineData.projectCostCenter,
                        });
                      } else {
                        subscriber.next(null);
                      }
                      subscriber.complete();
                    });
                }
              }),

              projectTariff: new Observable((subscriber) => {
                // Paste project tariff.
                if (!line || !lineData.projectTariff) {
                  subscriber.next(null);
                  subscriber.complete();
                } else {
                  this.tariffService
                    .getTariffs(lineData.task.project.id)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((tariffs) => {
                      if (
                        tariffs.find((a) => a.id === lineData.projectTariff.id)
                      ) {
                        subscriber.next({
                          projectTariff: lineData.projectTariff,
                        });
                      } else {
                        subscriber.next(null);
                      }
                      subscriber.complete();
                    });
                }
              }),
            }),
          ),

          map((result) => {
            if (!result.line) {
              return null;
            }

            assign(result.line, result.role);
            assign(result.line, result.activity);
            assign(result.line, result.projectCostCenter);
            assign(result.line, result.projectTariff);

            return result.line;
          }),
        );
    });

    forkJoin(observables).subscribe((addedLines) => {
      linesData.forEach((lineData) => {
        const patch = addedLines[lineData.id];
        if (!patch) {
          return;
        }
        const line = this.addLine();
        line.patchValue(patch);
        this.selectControls.at(this.dataLines.length - 1).setValue(true);
      });

      this.hasLinesInStorage$.next(false);
      this.localStorageService.clear(this.service.timesheetLinesStorageName);
      this.blockUI.stop();
    });
  }

  /** Moves selected rows down. */
  public moveLinesDown() {
    for (let index = this.dataLines.length - 1; index >= 0; index--) {
      if (
        this.selectControls.controls[index].value &&
        this.dataLines.length - index - 1 > 0
      ) {
        const group = this.dataLines.at(index);
        this.dataLines.removeAt(index);
        this.dataLines.insert(index + 1, group);

        const control = this.selectControls.at(index);
        this.selectControls.removeAt(index);
        this.selectControls.insert(index + 1, control);
      }
    }

    setTimeout(() => this.cellsOrchestrator.reset());
  }

  /** Moves selected rows up. */
  public moveLinesUp() {
    for (let index = 0; index < this.dataLines.length; index++) {
      if (this.selectControls.controls[index].value && index > 0) {
        const group = this.dataLines.at(index);
        this.dataLines.removeAt(index);
        this.dataLines.insert(index - 1, group);

        const control = this.selectControls.at(index);
        this.selectControls.removeAt(index);
        this.selectControls.insert(index - 1, control);
      }
    }

    setTimeout(() => this.cellsOrchestrator.reset());
  }

  /** Adds form row. */
  public addLine(): UntypedFormGroup {
    this.selectControls.push(new UntypedFormControl(false));

    const group = this.fb.group({
      id: Guid.generate(),
      task: {
        client: null,
        project: null,
        projectTask: null,
      } as Task,
      activity: null,
      role: null,
      projectCostCenter: null,
      projectTariff: null,
      allocations: this.fb.array([]),
      totalHours: 0,
    });

    this.subscribeDataLineGroup(group);

    this.fillOutAllocationsGroup(group);
    this.customFieldService.enrichFormGroup(group, 'TimeSheetLine');
    this.customFieldService.enrichFormGroupWithDefaultValues(
      group,
      'TimeSheetLine',
    );
    this.dataLines.push(group);

    return group;
  }

  /** Copies lines from the previous timesheet.
   *
   * @param withHours include hours
   */
  public copyLines(withHours?: boolean) {
    this.service.copyLines(withHours);
  }

  /** Creates lines from the resource plan.
   *
   * @param withHours include hours
   */
  public createLinesFromResourcePlan(withHours?: boolean) {
    this.service.createLinesFromResourcePlan(withHours);
  }

  /** Adds an time-off request. */
  public addTimeOffRequest() {
    this.service.createTimeOffRequest();
  }

  /** Returns of the duration of time off during the day.
   *
   * @param request time-off request
   * @param day day on which
   *
   * @returns duration
   */
  public getDayOffDuration(request: TimeOffRequest, day: Day): number {
    const allocation = request.timeAllocations.find((a) => a.date === day.date);
    return allocation?.duration;
  }

  /** Returns of the duration of time off during the period.
   *
   * @param request time-off request
   *
   * @returns duration
   */
  public geTimeOffRequestDuration(request: TimeOffRequest): number {
    let duration = 0;

    this.days.forEach((day) => {
      const allocation = request.timeAllocations.find(
        (a) => a.date === day.date,
      );
      duration += allocation?.duration ?? 0;
    });
    return duration;
  }

  public trackId = (index: number, item: any) => item.id;

  /** Calculates line amount.
   *
   * @param group line form group
   */
  private calculateGroupTotal = (group: UntypedFormGroup) =>
    group.controls['totalHours'].setValue(
      sumBy(
        group.controls['allocations'].value,
        (allocation: TimeAllocation) => allocation.duration ?? 0,
      ),
      { emitEvent: false },
    );

  /** Calculates table widths. */
  private calculateWidth() {
    this.fixTableColumnCount = 2;

    this.fixTableWidth =
      this.columnsWidths.taskColumnWidth +
      this.columnsWidths.checkBoxColumnWidth;
    if (this.template.showActivity) {
      this.fixTableWidth += this.columnsWidths.activityColumnWidth;
      this.fixTableColumnCount++;
    }

    if (this.template.showRole) {
      this.fixTableWidth += this.columnsWidths.roleRateColumnWidth;
      this.fixTableColumnCount++;
    }

    if (this.template.showProjectCostCenter) {
      this.fixTableWidth += this.columnsWidths.projectCostCenterColumnWidth;
      this.fixTableColumnCount++;
    }

    if (this.template.showTariff) {
      this.fixTableWidth += this.columnsWidths.projectTariffColumnWidth;
      this.fixTableColumnCount++;
    }

    this.fixTableWidth +=
      this.columnsWidths.customColumnWidth * this.lineCustomFields.length;
    this.fixTableColumnCount += this.lineCustomFields.length;
    this.dataTableWidth = this.days.length * 47 + 53;
  }

  /** Fills the timesheet days array. */
  private fillOutDays() {
    this.days = [];
    this.totalSchedule = 0;

    // Заполняем дни.
    const dateTo = DateTime.fromISO(this.service.timesheet.dateTo);
    let currentDate = DateTime.fromISO(this.service.timesheet.dateFrom);

    while (currentDate <= dateTo) {
      let hintAddon = '';
      let schedule = 0;
      let isExceptionDay = false;

      if (this.service.timesheet.schedule) {
        const scheduleDay = this.service.timesheet.schedule.find(
          (d) => d.date === currentDate.toFormat('yyyy-MM-dd'),
        );
        if (scheduleDay) {
          isExceptionDay = scheduleDay.hours === 0;
          schedule = scheduleDay.hours;

          // Добавить длительность по расписанию к общему итогу.
          this.totalSchedule += scheduleDay.hours ?? 0;

          hintAddon +=
            '\n' +
            this.translate.instant('timesheets.dayHintWorkHours', {
              hours: scheduleDay.hours,
            });
        }
      }

      const day = {
        schedule,
        date: currentDate.toFormat('yyyy-MM-dd'),
        header: currentDate.toFormat('dd.LL'),
        hint: currentDate.toLocaleString(DateTime.DATE_FULL) + hintAddon,
        stamp: currentDate.valueOf(),
        isException: isExceptionDay,
        isToday: currentDate.hasSame(DateTime.now(), 'day'),
      } as Day;
      this.days.push(day);
      currentDate = currentDate.plus({ days: 1 });
    }
  }

  /** Fills FormArray with timesheet data. */
  private fillOutDataLines() {
    this.autosave.disabled = true;

    this.dataLines.clear();

    // Заполнить строки данных.
    this.service.timesheet.timeSheetLines.forEach((line) => {
      const group = this.fb.group({
        id: line.id,
        task: {
          client: line.project?.organization,
          project: line.project
            ? {
                id: line.project?.id,
                name: line.project?.name,
              }
            : null,
          projectTask: line.projectTask,
          isMainTask: line.projectTask && !line.projectTask.leadTaskId,
          billingTypeCode: line.project?.billingType.code,
        } as Task,
        activity: line.activity,
        role: line.role,
        projectCostCenter: line.projectCostCenter,
        projectTariff: [
          {
            value: line.projectTariff,
            disabled:
              line.project?.billingType?.code ===
              ProjectBillingType.nonBillable.code,
          },
        ],
        allocations: this.fb.array([]),
        totalHours: 0,
      });

      this.subscribeDataLineGroup(group);

      this.customFieldService.enrichFormGroup(group, 'TimeSheetLine');
      this.lineCustomFields.forEach((field) => {
        group.controls[field.name].setValue(line[field.name], {
          emitEvent: false,
        });
      });

      this.fillOutAllocationsGroup(group, line);
      this.dataLines.push(group);
    });

    this.selectControls.clear();
    this.dataLines.controls.forEach(() =>
      this.selectControls.push(new UntypedFormControl(false)),
    );

    this.service.changeData(this.dataLines.value as Line[]);
    this.autosave.disabled = false;
  }

  private calculateTotals() {
    this.totalHours = 0;
    this.days.forEach((day) => (day.totalHours = 0));

    const lines = this.dataLines.value as Line[];

    lines.forEach((line) => {
      line.allocations.forEach((allocation, index) => {
        this.days[index].totalHours += allocation.duration ?? 0;
        this.totalHours += allocation.duration ?? 0;
      });
    });

    this.days.forEach((day) => {
      this.service.timesheet.timeOffRequests.forEach((line) => {
        const allocation = line.timeAllocations.find(
          (a) => a.date === day.date,
        );
        if (allocation) {
          day.totalHours += allocation.duration ?? 0;
          this.totalHours += allocation.duration ?? 0;
        }
      });
    });
  }

  private fillOutAllocationsGroup(
    group: UntypedFormGroup,
    line?: TimesheetLine,
  ) {
    const allocations = group.controls.allocations as UntypedFormArray;

    // Заполняем дни.
    const dateTo = DateTime.fromISO(this.service.timesheet.dateTo);
    let currentDate = DateTime.fromISO(this.service.timesheet.dateFrom);
    while (currentDate <= dateTo) {
      let allocation: TimeAllocation;

      if (line) {
        allocation = line.timeAllocations.find(
          (a) => a.date === currentDate.toFormat('yyyy-MM-dd'),
        );
      }

      if (!allocation) {
        allocation = {
          id: Guid.generate(),
          date: currentDate.toFormat('yyyy-MM-dd'),
          duration: null,
          comments: '',
        };
      }

      allocations.push(this.fb.control(allocation));
      currentDate = currentDate.plus({ days: 1 });
    }
  }

  /** Performs subscription of the line group to events.
   *
   * @param group line form group
   */
  private subscribeDataLineGroup(group: UntypedFormGroup) {
    group.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.calculateGroupTotal(group));
  }
}
