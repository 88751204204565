import {
  Component,
  OnInit,
  Input,
  Injector,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { IssueCardService } from 'src/app/issues/card/issue-card.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { tap } from 'rxjs';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { IssueCardSettings } from 'src/app/issues/models/issue.settings';
import { UpdatableSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { ChangingIssueTypeModalComponent } from 'src/app/issues/card/changing-issue-type-modal/changing-issue-type-modal.component';

@Component({
  selector: 'tmt-issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss'],
  providers: [
    { provide: META_ENTITY_TYPE, useValue: 'Issue' },
    LifecycleService,
    IssueCardService,
    SavingQueueService,
    FormHeaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCardComponent implements OnInit {
  @Input() public entityId: string;

  public activeTab: string;
  public settings: IssueCardSettings;
  public isInOffCanvas: boolean;
  public readonly commentedEntityCollectionType = CommentedEntityCollectionType;
  public readonly entityType = inject(META_ENTITY_TYPE);
  /**
   * Saves issue name.
   *
   * @param name issue name.
   */
  public saveName = (name: string) =>
    this.dataService
      .collection('Issues')
      .entity(this.entityId)
      .patch({ name })
      .pipe(
        tap(() => {
          this.issueCardService.issueForm
            .get('name')
            .setValue(name, { emitEvent: false });
          this.offCanvasService.onEntityUpdated({ id: this.entityId, name });
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      );

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public issueCardService: IssueCardService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private actionPanelService: ActionPanelService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private offCanvasService: OffCanvasService,
    private localConfigService: LocalConfigService,
    private modalService: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.isInOffCanvas = this.offCanvasService.isEntryEqualComponent(this);
    this.settings = Object.assign(
      {},
      this.localConfigService.getConfig(IssueCardSettings),
    );

    if (!this.isInOffCanvas) {
      this.settings.parametersCollapsed = false;
    }

    this.actionPanelService.setHasAutosave(true);
    this.issueCardService.load();

    this.actionPanelService.setAdditional([
      {
        title: 'components.issueCardComponent.actions.changeType',
        hint: 'components.issueCardComponent.actions.changeType',
        name: 'openChangeTypeModal',
        isBusy: false,
        isVisible: false,
        handler: () => this.openChangeTypeModal(),
      },
    ]);
  }

  /**
   * Opens created by user info.
   *
   * @param target target element for info popup service.
   */
  public openCreatedByUserInfo(target: ElementRef): void {
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId: this.issueCardService.issue.createdBy.id,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Updates issue card settings.
   *
   * @param key setting's property.
   * @param value
   */
  public updateSettings(
    key: UpdatableSettings<IssueCardSettings>,
    value: boolean,
  ): void {
    if (this.isInOffCanvas) {
      this.settings[key] = value;
      this.localConfigService.setConfig(IssueCardSettings, this.settings);
    }
  }

  /**
   * Sets mentioned users ids form value.
   *
   * @param userIds users ids array.
   */
  public onMentionIds(userIds: string[]): void {
    userIds.forEach((id) => {
      if (!this.issueCardService.formValue.mentionedUserIds.includes(id)) {
        this.issueCardService.issueForm
          .get('mentionedUserIds')
          .setValue([...this.issueCardService.formValue.mentionedUserIds, id], {
            emitEvent: false,
          });
        this.issueCardService.isUpdateFollowers = true;
      }
    });
  }

  /** Opens a modal for changing issue type. */
  public openChangeTypeModal(): void {
    const modalRef = this.modalService.open(ChangingIssueTypeModalComponent);
    modalRef.componentInstance.issue = this.issueCardService.issue;

    modalRef.result.then(
      () => {
        this.issueCardService.reload();
      },
      () => null,
    );
  }
}
