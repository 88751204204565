import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { LeadCardService } from './lead-card.service';

@Component({
  selector: 'tmt-lead-card',
  templateUrl: './lead-card.component.html',
  providers: [LeadCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadCardComponent implements OnInit {
  public tabs: TabLink[] = [];

  constructor(
    public state: StateService,
    public service: LeadCardService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reset();
    this.service.load();

    this.tabs.push({
      header: 'shared2.groups.profile',
      state: 'lead.profile',
    });
  }
}
