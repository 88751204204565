import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { SettingsComponent } from './settings.component';
import { SettingsMainComponent } from './settings-main/settings-main.component';
import { SettingsBillingComponent } from './settings-billing/settings-billing.component';
import { SettingsSystemComponent } from './settings-system/settings-system.component';
import { SettingsDomainsComponent } from './settings-domains/settings-domains.component';
import { SettingsDataDeletionComponent } from './settings-data-deletion/settings-data-deletion.component';
import { DomainAddingComponent } from './settings-domains/domain-adding/domain-adding.component';
import { DomainConfirmationComponent } from './settings-domains/domain-confirmation/domain-confirmation.component';
import { environment } from 'src/environments/environment';
import { SettingsSecurityComponent } from './settings-security/settings-security.component';
import { SettingsAuthProvidersComponent } from 'src/app/settings-app/settings/settings-security/settings-auth-providers/settings-auth-providers.component';
import { SettingsAuthCreateProviderModalComponent } from './settings-security/settings-auth-create-provider-modal/settings-auth-create-provider-modal.component';
import { SettingsApplicationsComponent } from './settings-applications/settings-applications.component';
import { BookingModeChangeDialogComponent } from 'src/app/settings-app/settings/settings-applications/booking-mode-change-dialog/booking-mode-change-dialog.component';
import { Grid2Module } from '../../shared-features/grid2/grid2.module';
import { CapitalChargesToolbarComponent } from 'src/app/settings-app/settings/settings-applications/capital-charges-toolbar/capital-charges-toolbar.component';

@NgModule({
  declarations: [
    SettingsComponent,
    SettingsMainComponent,
    SettingsBillingComponent,
    SettingsSystemComponent,
    SettingsDomainsComponent,
    SettingsDataDeletionComponent,
    DomainAddingComponent,
    DomainConfirmationComponent,
    SettingsSecurityComponent,
    SettingsAuthProvidersComponent,
    SettingsAuthCreateProviderModalComponent,
    BookingModeChangeDialogComponent,
    SettingsApplicationsComponent,
    CapitalChargesToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.settings',
          params: {
            navigation: 'settings.settings',
          },
          url: '/settings',
          component: SettingsComponent,
          redirectTo: environment.onprem
            ? 'settings.settings.system'
            : 'settings.settings.main',
        },

        {
          name: 'settings.settings.main',
          url: '/main',
          component: SettingsMainComponent,
        },
        {
          name: 'settings.settings.billing',
          url: '/billing',
          component: SettingsBillingComponent,
        },
        {
          name: 'settings.settings.system',
          url: '/system',
          component: SettingsSystemComponent,
        },
        {
          name: 'settings.settings.applications',
          url: '/applications',
          component: SettingsApplicationsComponent,
        },
        {
          name: 'settings.settings.security',
          url: '/security',
          component: SettingsSecurityComponent,
        },
        {
          name: 'settings.settings.domains',
          url: '/domains',
          component: SettingsDomainsComponent,
        },
        {
          name: 'settings.settings.dad',
          url: '/data-deletion',
          component: SettingsDataDeletionComponent,
        },
      ],
    }),
    Grid2Module,
  ],
})
export class SettingsModule {}
