import {
  Component,
  OnInit,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { IssueTypeCardService } from './issue-type-card.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IssueType } from 'src/app/settings-app/issue-types/model/issue-type.model';
import { IssueTypePermissionsComponent } from 'src/app/settings-app/issue-types/card/permissions/issue-type-permissions.component';
import { filter } from 'rxjs';

@Component({
  selector: 'tmt-issue-type-card',
  templateUrl: './issue-type-card.component.html',
  styleUrl: './issue-type-card.component.scss',
  providers: [IssueTypeCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueTypeCardComponent implements OnInit {
  @ViewChild(IssueTypePermissionsComponent)
  private permissionsComponent: IssueTypePermissionsComponent;

  public readonly = !this.appService.checkEntityPermission(
    'IssueType',
    PermissionType.Modify,
  );

  /** Detects changes in performers components. */
  public detectPerformersChanges = () => {
    this.permissionsComponent.performersComponents.forEach((component) =>
      component.performersService.detectChanges(),
    );
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    public issueTypeCardService: IssueTypeCardService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.issueTypeCardService.reload();
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: !this.readonly,
        handler: () =>
          this.issueTypeCardService.save(this.detectPerformersChanges),
      },
      this.actionPanelService.getDefaultAction('setAsDefault', {
        handler: () => this.issueTypeCardService.setAsDefault(),
      }),
    ]);

    this.issueTypeCardService.form.controls['name'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.issueTypeCardService.updateName(value);
      });

    this.actionPanelService.action('save').isShown = !this.readonly;

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.issueTypeCardService.reload());

    this.issueTypeCardService.issueType$
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((issueType: IssueType) => {
        this.issueTypeCardService.form.patchValue(issueType);
        this.readonly = !issueType.editAllowed;
        this.readonly
          ? this.issueTypeCardService.form.disable()
          : this.issueTypeCardService.form.enable();
      });
    this.issueTypeCardService.saveMembers$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.permissionsComponent.saveMembers();
      });
  }
}
