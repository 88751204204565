import { NamedEntity } from '../named-entity.model';
import { CodedEntity } from '../coded-entity.model';
import { CustomizableEntity } from '../customizable-entity.interface';
import { Contact } from 'src/app/contacts/model/contact.model';

/** Клиент */
export class Client extends CodedEntity implements CustomizableEntity {
  /** Менеджер. */
  manager: NamedEntity;

  /** Описание. */
  description: string;

  /** Контактное лицо. */
  contact: string;

  /** Контактное лицо. */
  contacts: Contact[];

  /** Эл. почта контакта. */
  contactEmail: string;

  /** Веб-сайт. */
  site: string;

  /** Телефон. */
  phone: string;

  /** Почтовый адрес. */
  postAddress: OrganizationAddress;
  /** Адрес для выставления счетов. */
  billingAddress: OrganizationAddress;
  /** Признак идентичности адресов. */
  billingEqualPost: boolean;

  /** Признак доступности ролей клиента на редактирование.
   * Динамическое свойств ролевой модели.
   */
  rolesViewAllowed?: boolean;

  /** Признак доступности тарифов клиента на редактирование.
   * Динамическое свойств ролевой модели.
   */
  rolesEditAllowed?: boolean;

  /** Признак доступности счетов по клиенту на просмотр.
   * Динамическое свойств ролевой модели.
   */
  invoicesViewAllowed?: boolean;

  //#region Customizable properties
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;

  tariffsViewAllowed?: boolean;
  tariffsEditAllowed?: boolean;

  //#endregion
}

/** Адрес клиента. */
export class OrganizationAddress {
  /** Штат. */
  state: string;

  /** Город. */
  city: string;

  /** Адрес. */
  address: string;

  /** Почтовый индекс. */
  postIndex: string;
}
