@if ((boardCardService.state$ | async) === 'Ready') {
  <div class="row mt-4">
    <div class="col-12">
      <label class="group mb-0">
        {{ 'components.boardCardPermissionsComponent.groups.header' | translate }}
      </label>
    </div>
  </div>
  <wp-performers
    class="p-0"
    [performerLines]="permissions"
    [readonly]="readonly"
    [predefinedRoles]="[{ id: 'All', name: 'shared2.props.roleAll' | translate }]"
    [gridHeader]="'components.boardCardPermissionsComponent.groups.gridHeader' | translate"
  />
}
