<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.changingIssueTypeModalComponent.groups.header' | translate }}
    </h3>
    <button [disabled]="isSaving()" type="button" class="btn-close" (click)="closeModal()"></button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>{{
        'components.changingIssueTypeModalComponent.props.currentType' | translate
      }}</label>
      <p class="pt-1">{{ issue.type.name }}</p>
    </div>

    <div class="form-group" style="width: 350px" tmtIndicator>
      <label>{{
        'components.changingIssueTypeModalComponent.props.newType.value' | translate
      }}</label>
      <wp-select-box
        [values]="types$"
        placeholder="{{
          'components.changingIssueTypeModalComponent.props.newType.ph' | translate
        }}"
        formControlName="type"
      >
        <ng-template #prefixTemplate let-data="data">
          <i class="me-1 bi {{ data?.iconClass }}"></i>
        </ng-template>

        <ng-template #rowTemplate let-data="data">
          <i class="me-1 bi {{ data.iconClass }}"></i>
          {{ data.name }}
        </ng-template>
      </wp-select-box>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="changeType()"
      wpButtonSpinner
      [isBusy]="isSaving()"
    >
      {{ 'shared2.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="closeModal()" [disabled]="isSaving()">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</form>
