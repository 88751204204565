<wp-loading-indicator [loading]="isLoading$ | async" />
<form class="modal-form" novalidate ngDraggable [formGroup]="form" *ngIf="!(isLoading$ | async)">
  <div class="modal-header">
    <h3 class="modal-title" *ngIf="!isEditing">
      {{ 'projects.projects.card.team.genericModal.header' | translate }}
    </h3>
    <h3 class="modal-title" *ngIf="isEditing">{{ form.value.name }}</h3>

    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body row">
    @if (hasResourceRequest) {
      <p class="text-danger">
        {{ 'projects.projects.card.team.genericModal.nonEditable' | translate }}
      </p>
    }
    <div class="col-8">
      <div class="row">
        <div class="col-6">
          <div class="form-group" tmtIndicator>
            <label>{{ 'shared.props.name' | translate }}</label>
            <wp-text-box
              formControlName="name"
              placeholder="{{ 'shared.props.name' | translate }}"
            />
          </div>
        </div>

        <div class="col-6">
          @if (projectCardService.isProjectBillable) {
            <div class="form-group">
              <label>
                {{ 'projects.projects.card.team.columns.primaryTariff.header' | translate }}
              </label>
              <wp-select-box
                formControlName="primaryTariff"
                [query]="projectTeamService.getProjectTariffQuery(projectId, resourceId)"
                collection="ProjectTariffs"
                [placeholder]="
                  'projects.projects.card.team.columns.primaryTariff.header' | translate
                "
                [allowNull]="false"
              />
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          @if (matrixAnalytics?.length) {
            <label class="group">{{
              'projects.projects.card.team.genericModal.labels.matrix' | translate
            }}</label>
            @for (analytic of matrixAnalytics; track analytic) {
              <ng-container
                [ngTemplateOutlet]="analyticControls"
                [ngTemplateOutletContext]="{ analytic }"
              />
            }
          } @else {
            <ng-container
              [ngTemplateOutlet]="other"
              [ngTemplateOutletContext]="{ otherAnalytics }"
            />
          }
        </div>
        @if (matrixAnalytics?.length) {
          <div class="col-6">
            <ng-container
              [ngTemplateOutlet]="other"
              [ngTemplateOutletContext]="{ otherAnalytics }"
            />
          </div>
        }
      </div>
    </div>

    <div class="col-4">
      <div class="form-group">
        <label>{{ 'projects.projects.card.team.genericModal.props.rate' | translate }}</label>
        <wp-number-box readonly="true" formControlName="costRate" type="currency" />
      </div>

      <div class="form-group">
        <label>{{ 'projects.projects.card.team.genericModal.props.staff' | translate }}</label>
        <wp-text-box formControlName="staffCount" readonly="true" />
      </div>
    </div>

    <br />
  </div>
  <div class="modal-footer">
    <div class="btn-group" *ngIf="editAllowed">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving"
        (click)="ok(false)"
        *ngIf="!isEditing"
      >
        {{ 'shared.actions.create' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving"
        (click)="ok(false)"
        *ngIf="isEditing"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
      <div class="btn-group" ngbDropdown role="group" *ngIf="!isEditing">
        <div>
          <div class="dropdown-menu" ngbDropdownMenu placement="left">
            <button ngbDropdownItem (click)="ok(true)">
              {{ 'projects.projects.card.team.genericModal.actions.createAndAdd' | translate }}
            </button>
          </div>
        </div>
        <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
      </div>
    </div>

    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>

  <ng-template #analyticControls let-analytic="analytic">
    @switch (analytic) {
      @case ('role') {
        <ng-container [ngTemplateOutlet]="role" />
      }
      @case ('level') {
        <ng-container [ngTemplateOutlet]="level" />
      }
      @case ('grade') {
        <ng-container [ngTemplateOutlet]="grade" />
      }
      @case ('resourcePool') {
        <ng-container [ngTemplateOutlet]="resourcePool" />
      }
      @case ('location') {
        <ng-container [ngTemplateOutlet]="location" />
      }
      @case ('competence') {
        <ng-container [ngTemplateOutlet]="competence" />
      }
      @case ('legalEntity') {
        <ng-container [ngTemplateOutlet]="legalEntity" />
      }
      @case ('kind') {
        <ng-container [ngTemplateOutlet]="kind" />
      }
    }
  </ng-template>

  <ng-template #role>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.role' | translate }}</label>
      <wp-select-box
        #cascadeControl
        formControlName="role"
        collection="Roles"
        placeholder="{{ 'projects.projects.card.team.genericModal.props.role' | translate }}"
        [query]="{ select: ['id', 'name', 'code'] }"
      />
    </div>
  </ng-template>

  <ng-template #level>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.level' | translate }}</label>
      <wp-select-box
        #cascadeControl
        formControlName="level"
        collection="Levels"
        placeholder="{{ 'projects.projects.card.team.genericModal.props.level' | translate }}"
        [query]="{ select: ['id', 'name', 'code'] }"
      />
    </div>
  </ng-template>

  <ng-template #grade>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.grade' | translate }}</label>
      <wp-select-box
        #cascadeControl
        formControlName="grade"
        collection="Grades"
        placeholder="{{ 'projects.projects.card.team.genericModal.props.grade' | translate }}"
        [query]="{ select: ['id', 'name', 'code'] }"
      /></div
  ></ng-template>

  <ng-template #resourcePool>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.resourcePool' | translate }}</label>
      <tmt-hierarchical-box
        formControlName="resourcePool"
        collection="ResourcePools"
        parentIdKey="leadResourcePoolId"
        placeholder="{{
          'projects.projects.card.team.genericModal.props.resourcePool' | translate
        }}"
      /></div
  ></ng-template>

  <ng-template #location>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.location' | translate }}</label>
      <wp-select-box
        formControlName="location"
        collection="Locations"
        placeholder="{{ 'projects.projects.card.team.genericModal.props.location' | translate }}"
        [query]="{ select: ['id', 'name', 'code'] }"
      /></div
  ></ng-template>

  <ng-template #competence>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.competence' | translate }}</label>
      <wp-select-box
        #cascadeControl
        formControlName="competence"
        collection="Competences"
        placeholder="{{ 'projects.projects.card.team.genericModal.props.competence' | translate }}"
        [query]="{ select: ['id', 'name', 'code'] }"
      /></div
  ></ng-template>

  <ng-template #legalEntity>
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.genericModal.props.legalEntity' | translate }}</label>
      <wp-select-box
        formControlName="legalEntity"
        collection="LegalEntities"
        placeholder="{{ 'projects.projects.card.team.genericModal.props.legalEntity' | translate }}"
        [query]="{ select: ['id', 'name', 'code'] }"
      /></div
  ></ng-template>

  <ng-template #kind>
    <div class="form-group">
      <label>{{ 'shared2.props.kind' | translate }}</label>
      <wp-select-box
        formControlName="kindId"
        [isIdMode]="true"
        [directoryId]="systemDirectory.resourceKind"
        placeholder="{{ 'shared2.props.kind' | translate }}"
      /></div
  ></ng-template>

  <ng-template #other let-otherAnalytics="otherAnalytics">
    @if (otherAnalytics?.length) {
      <label class="group">{{
        'projects.projects.card.team.genericModal.labels.other' | translate
      }}</label>

      @for (analytic of otherAnalytics; track analytic) {
        <ng-container
          [ngTemplateOutlet]="analyticControls"
          [ngTemplateOutletContext]="{ analytic }"
        />
      }
    }
  </ng-template>
</form>
