import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpenseRequestLine } from 'src/app/shared/models/entities/base/expenses-request.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ExpenseRequestCardService } from 'src/app/expenses-requests/card/expenses-request-card.service';
import { Constants } from 'src/app/shared/globals/constants';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { AttachmentHelper } from 'src/app/shared/models/entities/attachments/attachment.helper';

/**
 * Represents Expense request line View/Edit modal window content.
 * */
@Component({
  selector: 'tmt-expenses-request-line-modal',
  templateUrl: './line-modal.component.html',
  styleUrls: ['./line-modal.component.scss'],
})
export class LineModalComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() line: ExpenseRequestLine;

  public lineForm: UntypedFormGroup;
  public maxAttachmentSize = Constants.maxAttachmentSize;

  constructor(
    public cardService: ExpenseRequestCardService,
    public blockUI: BlockUIService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.lineForm = this.cardService.getLineGroup(this.line, true);
    if (this.readonly) {
      this.lineForm.disable({ emitEvent: false });
    }
  }

  /**
   * Modal window Ok button click event handler.
   * Closes modal window and passes Expense request line form group as a result.
   * */
  ok = () => {
    this.lineForm.markAllAsTouched();

    if (this.lineForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.cardService.modalClosed$.next();
    this.activeModal.close(this.lineForm);
  };

  /**
   * Modal window Cancel/Dismiss button click event handler.
   * Closes modal window and passes Expense request line attachment as a result.
   * */
  cancel = () => {
    this.cardService.modalClosed$.next();
    this.activeModal.dismiss(this.lineForm.controls.attachment.value);
  };

  /**
   * Drag'n'Drop file add event handler.
   * Uploads Expense request line attachment.
   *
   * @param event Drag'n'Drop event.
   * */
  public onFileAdded(event: NgxDropzoneChangeEvent): void {
    const file = event.addedFiles[0];

    if (!file) {
      this.notification.warningLocal('shared.messages.attachmentIsWrong');
      return;
    }

    if (!AttachmentHelper.isValidFormat([file])) {
      this.notification.errorLocal('shared.messages.attachmentWrongFormat');
      return;
    }

    this.cardService.uploadAttachment(this.line.id, file).then(
      (attachment) => {
        this.line.attachment = attachment;
        this.lineForm.controls.attachment.setValue(attachment);
      },
      () => null,
    );
  }

  /**
   * Remove attachment button click event handler.
   * Removes Expense request line attachment.
   * */
  public removeAttachment() {
    this.cardService.removeAttachment(this.line.id).then(
      () => {
        this.line.attachment = null;
        this.lineForm.controls.attachment.setValue(null);
      },
      () => null,
    );
  }

  /**
   * Open attachment button click event handler.
   * Opens Expense request line attachment.
   * */
  public openAttachment() {
    this.cardService.openAttachment(
      this.line.id,
      this.line.attachment.fileName,
    );
  }
}
