import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { BoardNavigation } from 'src/app/settings-app/boards/model/board.model';
import { EntityNavigation } from 'src/app/shared/models/navigation/navigation';

@Component({
  selector: 'wp-dashboard-properties',
  templateUrl: './dashboard-properties.component.html',
})
export class DashboardPropertiesComponent {
  @Input() form: UntypedFormGroup;
  @Input() navigationResponse: EntityNavigation[];
  @Input() navigations: FormArray;
}
