import { DestroyRef, Inject, Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Contact } from 'src/app/contacts/model/contact.model';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { MessageService } from 'src/app/core/message.service';

@Injectable()
export class ContactCardService {
  private contactNameSubject = new BehaviorSubject<string>(null);
  public contactName$ = this.contactNameSubject.asObservable();
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public contactForm = this.fb.group({
    firstName: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    lastName: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    patronymic: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    organizationId: null,
    roleId: null,
    position: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    email: [
      '',
      [Validators.maxLength(Constants.formTextMaxLength), Validators.email],
    ],
    mobilePhone: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    phone: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    isActive: null,
  });
  public isLoading = signal<boolean>(false);
  public isSaving = signal<boolean>(false);
  public readonly: boolean;
  public contact: Contact;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId')
    private contactId: string,
    private data: DataService,
    private notification: NotificationService,
    private navigation: NavigationService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {}

  /** Contact loading. */
  public load(): void {
    this.contactForm.markAsPristine();
    this.contactForm.markAsUntouched();

    this.isLoading.set(true);

    const query = {
      select: [
        'name',
        'firstName',
        'lastName',
        'patronymic',
        'organizationId',
        'roleId',
        'position',
        'description',
        'email',
        'mobilePhone',
        'phone',
        'editAllowed',
        'isActive',
      ],
    };

    this.data
      .collection('Contacts')
      .entity(this.contactId)
      .get<Contact>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (contact: Contact) => {
          this.contactForm.patchValue(contact);
          this.contact = contact;
          this.isLoading.set(false);
          this.readonly = !contact.editAllowed;

          this.state$.next(CardState.Ready);
          this.contactNameSubject.next(contact.name);
          this.navigation.addRouteSegment({
            id: this.contactId,
            title: contact.name,
          });
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  /** Contact saving. */
  public save(): void {
    this.contactForm.markAllAsTouched();
    const contact = this.contactForm.value as Contact;

    if (!this.contactForm.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving.set(true);

    const data: any = {
      name: `${contact.firstName} ${contact.lastName}`,
      firstName: contact.firstName,
      lastName: contact.lastName,
      patronymic: contact.patronymic,
      position: contact.position,
      organizationId: contact.organizationId,
      roleId: contact.roleId,
      description: contact.description,
      email: contact.email,
      mobilePhone: contact.mobilePhone,
      phone: contact.phone,
      isActive: contact.isActive,
    };

    this.data
      .collection('Contacts')
      .entity(this.contactId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.contactForm.markAsPristine();
          this.contactForm.disable();
          this.contact = { ...data };
          this.contactNameSubject.next(this.contact.name);
          this.isSaving.set(false);
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.message.errorDetailed(error);
        },
      });
  }
}
