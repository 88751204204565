import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
} from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { merge } from 'rxjs';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ListService } from 'src/app/shared/services/list.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { DIRECTORY_ENTRIES_LIST } from 'src/app/settings-app/directories/card/entries/model/directory-entries.list';
import { DirectoryCardService } from 'src/app/settings-app/directories/card/directory-card.service';
import { DirectoryEntriesToolbarComponent } from 'src/app/settings-app/directories/card/entries/toolbar/directory-entries-toolbar.component';

@Component({
  selector: 'tmt-directory-card',
  templateUrl: './directory-card.component.html',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: DIRECTORY_ENTRIES_LIST },
    EntityListService,
    ListService,
    GridService,
    SavingQueueService,
    DirectoryCardService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryCardComponent implements OnInit {
  public gridOptions: Grid2Options;
  public cardState = CardState;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId,
    public gridService: GridService,
    public directoryCardService: DirectoryCardService,
    private actionPanelService: ActionPanelService,
    private listService: ListService,
    private filterService: FilterService,
    public savingQueueService: SavingQueueService,
  ) {}

  public ngOnInit(): void {
    this.savingQueueService.delayDuration = 0;
    this.actionPanelService.set([
      {
        title: 'shared2.actions.properties',
        hint: 'shared2.actions.properties',
        name: 'properties',
        isBusy: false,
        isVisible: true,
        handler: () => this.directoryCardService.openDirectoryPropertiesModal(),
      },
    ]);
    this.actionPanelService.setHasAutosave(true);

    this.prepareGridOptions();
    this.directoryCardService.loadDirectory();
    this.directoryCardService.loadDirectoryEntries();
    this.directoryCardService.enableInfinityScroll();

    merge(
      this.filterService.values$,
      this.savingQueueService.error$,
      this.actionPanelService.reload$,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.directoryCardService.reload();
      });
  }

  public ngOnDestroy(): void {
    this.directoryCardService.scrollSubscription?.unsubscribe();
  }

  private prepareGridOptions(): void {
    this.gridOptions = {
      toolbar: DirectoryEntriesToolbarComponent,
      selectionType: SelectionType.row,
      resizableColumns: true,
      commands: [
        {
          name: 'create',
          handlerFn: () => {
            this.directoryCardService.openDirectoryEntryModal();
          },
          allowedFn: () => !this.directoryCardService.readonly,
        },
        {
          name: 'edit',
          allowedFn: (group) => group && !this.directoryCardService.readonly,
          handlerFn: () => {
            this.directoryCardService.openDirectoryEntryModal(
              this.gridService.selectedGroupValue,
            );
          },
        },
        {
          name: 'delete',
          allowedFn: (group) => !!group && true,
          handlerFn: (id) => {
            this.directoryCardService.deleteDirectoryEntry(id);
          },
        },
      ],
      rowCommands: [
        {
          name: 'edit',
          label: 'shared.actions.edit',
          handlerFn: (group) => {
            this.directoryCardService.openDirectoryEntryModal(
              group.getRawValue(),
            );
          },
          allowedFn: () => !this.directoryCardService.readonly,
        },
        {
          name: 'delete',
          label: 'shared.actions.delete',
          handlerFn: (group) => {
            this.directoryCardService.deleteDirectoryEntry(
              group.getRawValue().id,
            );
          },
          allowedFn: () => !this.directoryCardService.readonly,
        },
      ],
      view: this.listService.getGridView(),
    };
  }
}
