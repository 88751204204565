import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserCardService } from '../user-card.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { Guid } from 'src/app/shared/helpers/guid';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';

@Component({
  selector: 'wp-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  readonly = !this.app.checkEntityPermission('User', PermissionType.Modify);

  public isLoading: boolean;

  public avatarIsLoading: boolean;
  public avatarVersion: string = Guid.generate();

  public isUserUnlocking: boolean;
  public isUserLocked$ = new BehaviorSubject<boolean>(false);

  private destroyed$ = new Subject<void>();

  public authProviders$ = this.data
    .collection('Users')
    .function('GetAuthProviders')
    .query<AuthProvider[]>();

  private defaultGradesQuery = {
    select: ['name', 'id', 'levelId'],
  };

  reloadListener: Subscription;

  private _isSaving: boolean;

  get isSaving() {
    return this._isSaving;
  }

  set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }

  profileForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    authProvider: [null, Validators.required],
    email: ['', [Validators.required, Validators.email]],
    notificationsEmail: ['', Validators.email],
    code: ['', Validators.maxLength(Constants.formCodeMaxLength)],
    phone: ['', Validators.maxLength(Constants.formNameMaxLength)],
    location: [null],
    level: [null],
    grade: [null],
    position: ['', Validators.maxLength(Constants.formNameMaxLength)],
    resourcePool: [null, Validators.required],
    firstWorkDay: [null],
    lastWorkDay: [null],
    supervisor: [null],
    department: [null],
    isActive: [false],
    legalEntity: [null, Validators.required],
    role: null,
    competence: null,
    kindId: null,
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Feature = Feature;

  public showNotificationsEmail = false;

  constructor(
    private customFieldService: CustomFieldService,
    public app: AppService,
    private notification: NotificationService,
    private blockUI: BlockUIService,
    private data: DataService,
    private message: MessageService,
    private actionService: ActionPanelService,
    private service: UserCardService,
    private fb: UntypedFormBuilder,
  ) {
    this.customFieldService.enrichFormGroup(this.profileForm, 'User');
  }

  /** Сохранение профиля. */
  save() {
    this.profileForm.markAllAsTouched();
    const user = this.profileForm.value as User;

    if (this.profileForm.valid) {
      this.isSaving = true;

      const data: any = {
        name: user.name,
        authProviderId: this.profileForm.value.authProvider.id,
        locationId: user.location?.id ?? null,
        phone: user.phone,
        email: user.email,
        notificationsEmail: user.notificationsEmail,
        levelId: user.level?.id ?? null,
        gradeId: user.grade?.id ?? null,
        position: user.position,
        isActive: user.isActive,
        supervisorId: user.supervisor?.id ?? null,
        resourcePoolId: user.resourcePool?.id ?? null,
        code: user.code,
        departmentId: user.department?.id ?? null,
        firstWorkDay: user.firstWorkDay,
        lastWorkDay: user.lastWorkDay,
        legalEntityId: user.legalEntity?.id,
        roleId: user.role?.id ?? null,
        competenceId: user.competence?.id ?? null,
        kindId: user.kindId ?? null,
      };

      this.customFieldService.assignValues(data, user, 'User');

      this.data
        .collection('Users')
        .entity(this.entityId)
        .patch(data)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.messages.saved');
            this.profileForm.markAsPristine();
            this.isSaving = false;
            this.service.load();
          },
          error: (error: Exception) => {
            this.isSaving = false;
            this.message.errorDetailed(error);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  public onFileAdded(event: any) {
    const file = event.addedFiles[0];

    if (!file) {
      this.notification.warningLocal(
        'settings.users.card.profile.messages.avatarIsWrong',
      );
      return;
    }

    const formData: FormData = new FormData();
    this.avatarIsLoading = true;
    formData.append('image', file, file.name);

    this.data
      .collection('Users')
      .entity(this.entityId)
      .action('WP.UploadAvatar')
      .execute(formData)
      .subscribe({
        next: () => {
          this.avatarIsLoading = false;
          this.notification.successLocal(
            'settings.users.card.profile.messages.userAvatarSaved',
          );
          this.avatarVersion = Guid.generate();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.avatarIsLoading = false;
        },
      });
  }

  public load() {
    this.profileForm.markAsPristine();
    this.profileForm.markAsUntouched();

    this.actionService.action('save').hide();
    this.isLoading = true;

    const query = {
      select: [
        'name',
        'authProvider',
        'id',
        'email',
        'notificationsEmail',
        'position',
        'phone',
        'firstWorkDay',
        'lastWorkDay',
        'code',
        'editAllowed',
        'isActive',
        'kindId',
      ],
      expand: [
        { authProvider: { select: ['name', 'id', 'type'] } },
        { resourcePool: { select: ['name', 'id'] } },
        { department: { select: ['name', 'id'] } },
        { supervisor: { select: ['name', 'id'] } },
        { location: { select: ['name', 'id'] } },
        { level: { select: ['name', 'id'] } },
        { grade: { ...this.defaultGradesQuery } },
        { legalEntity: { select: ['name', 'id'] } },
        { role: { select: ['name', 'id'] } },
        { competence: { select: ['name', 'id', 'roleId'] } },
      ],
    };

    this.customFieldService.enrichQuery(query, 'User');

    this.data
      .collection('Users')
      .entity(this.entityId)
      .get<User>(query)
      .subscribe({
        next: (user: User) => {
          this.profileForm.patchValue(user);
          this.profileForm.markAsPristine();
          this.profileForm.markAsUntouched();

          // Доступность операций.
          if (user.editAllowed) {
            this.actionService.action('save').show();
            this.profileForm.enable();
          } else {
            this.profileForm.disable();
          }

          this.checkIsUserLocked();

          this.isLoading = false;
          this.showNotificationsEmail = !!user.notificationsEmail;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  /** Разблокировка пользователя */
  public unlockUser() {
    this.isUserUnlocking = true;
    this.data
      .collection('Users')
      .entity(this.entityId)
      .action('WP.UnlockUser')
      .execute()
      .subscribe({
        next: () => {
          this.isUserUnlocking = false;
          this.isUserLocked$.next(false);
        },
        error: (error) => {
          this.isUserUnlocking = false;
          this.message.errorDetailed(error);
        },
      });
  }

  private checkIsUserLocked() {
    this.data
      .collection('Users')
      .entity(this.entityId)
      .function('WP.IsUserLocked')
      .get<boolean>()
      .subscribe({
        next: (isLocked) => this.isUserLocked$.next(isLocked),
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (!this.profileForm.dirty) {
          this.load();
        } else {
          this.message.confirmLocal('shared.leavePageMessage').then(
            () => this.load(),
            () => null,
          );
        }
      });

    this.profileForm.controls['name'].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.updateName(this.profileForm.controls['name'].value);
      });

    FormHelper.controlDatePair(
      this.profileForm,
      'firstWorkDay',
      'lastWorkDay',
      takeUntil(this.destroyed$),
    );

    this.load();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
