import { StateDeclaration, StateObject } from '@uirouter/core';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Project task settings. */
export class ProjectTaskSettings
  implements BaseSettings, TaskAdditionalViewSettings
{
  public readonly name = 'ProjectTaskSettings';
  version: number;
  toggler: string | StateObject | StateDeclaration;
  isShowMainTask: boolean;
  isShowPlanTasks: boolean;
  isEnableSandbox: boolean;

  getDefault(): ProjectTaskSettings {
    return {
      version: 6,
      toggler: 'project.tasks.table',
      isShowMainTask: true,
      isShowPlanTasks: false,
      isEnableSandbox: false,
    } as ProjectTaskSettings;
  }
}

export interface TaskAdditionalViewSettings {
  isShowMainTask: boolean;
  isShowPlanTasks: boolean;
  isEnableSandbox: boolean;
}
