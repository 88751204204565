import {
  GridNavigationColumn,
  GridColumnType,
} from 'src/app/shared-features/grid2/models/grid-column.interface';

import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { ViewColumn } from 'src/app/shared/models/inner/view';

const defaultViewColumns: ViewColumn[] = [
  {
    column: 'name',
    visibleByDefault: true,
    width: 2,
    totalByDefault: TotalType.Count,
  },
  {
    column: 'code',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'initiator',
    visibleByDefault: true,
    width: 2,
  },
  {
    column: 'assigned',
    visibleByDefault: true,
    width: 2,
  },
  {
    column: 'dueDate',
    visibleByDefault: true,
    width: 150,
  },
  {
    column: 'type',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'priority',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'state',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'project',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'projectTask',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'resolution',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'resolutionComment',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'created',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'modified',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'isActive',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'createdBy',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'modifiedBy',
    visibleByDefault: false,
    width: 1,
  },
];

export const ISSUE_LIST: List = {
  version: 3,
  name: 'issues',
  dataCollection: 'Issues',
  customFieldEntityType: 'Issue',
  views: [
    {
      name: 'all',
      columns: defaultViewColumns,
      order: { column: 'name', reverse: false },
    },
    {
      name: 'my',
      columns: defaultViewColumns,
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          initiatorId: { type: 'guid', value: '#user' },
          isActive: true,
        },
      ],
    },
    {
      name: 'assigned',
      columns: defaultViewColumns,
      contextFilter: [
        {
          assignedId: { type: 'guid', value: '#user' },
          isActive: true,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      state: 'issue',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
    },
    {
      name: 'code',
      type: GridColumnType.String,
      header: 'shared2.props.code',
      hint: 'shared2.props.code',
    },
    {
      name: 'type',
      header: 'shared2.props.type',
      hint: 'shared2.props.type',
      type: GridColumnType.Entity,
    },
    {
      name: 'project',
      header: 'shared2.props.project',
      hint: 'shared2.props.project',
      type: GridColumnType.Entity,
    },
    {
      name: 'projectTask',
      header: 'shared2.props.projectTask',
      hint: 'shared2.props.projectTask',
      type: GridColumnType.Entity,
    },
    {
      name: 'resolution',
      header: 'components.issueListComponent.columns.resolution',
      hint: 'components.issueListComponent.columns.resolution',
      type: GridColumnType.Entity,
    },
    {
      name: 'resolutionComment',
      header: 'components.issueListComponent.columns.resolutionComment',
      hint: 'components.issueListComponent.columns.resolutionComment',
      type: GridColumnType.String,
    },
    {
      name: 'type',
      header: 'shared2.props.type',
      hint: 'shared2.type',
      type: GridColumnType.Entity,
    },
    {
      name: 'assigned',
      header: 'shared2.props.assigned',
      hint: 'shared2.props.assigned',
      type: GridColumnType.User,
    },
    {
      name: 'initiator',
      header: 'components.issueListComponent.columns.initiator',
      hint: 'components.issueListComponent.columns.initiator',
      type: GridColumnType.Entity,
    },
    {
      name: 'state',
      header: 'shared2.props.state',
      hint: 'shared2.props.state',
      type: GridColumnType.State,
    },
    {
      name: 'dueDate',
      header: 'components.issueListComponent.columns.dueDate',
      hint: 'components.issueListComponent.columns.dueDate',
      type: GridColumnType.Date,
      fixedWidth: true,
    },
    {
      name: 'priority',
      header: 'components.issueListComponent.columns.priority',
      hint: 'components.issueListComponent.columns.priority',
      type: GridColumnType.Entity,
    },
    {
      name: 'created',
      header: 'shared2.props.created.value',
      hint: 'shared2.props.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared2.props.modified.value',
      hint: 'shared2.props.modified.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared2.props.isActive.short',
      hint: 'shared2.props.isActive.value',
      type: GridColumnType.Boolean,
    },
    {
      name: 'createdBy',
      header: 'shared2.props.createdBy.value',
      hint: 'shared2.props.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared2.props.modifiedBy.value',
      hint: 'shared2.props.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'code',
      field: 'code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'dueDate',
      field: 'dueDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'type',
      field: ['type.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'assigned',
      field: ['assigned.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'initiator',
      field: ['initiator.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resolution',
      field: ['resolution.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resolutionComment',
      field: 'resolutionComment',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'priority',
      field: ['priority.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'project',
      field: ['project.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectTask',
      field: ['projectTask.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
