import { DateTime } from 'luxon';
import {
  ProjectTask,
  ProjectTaskDependency,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';

/** Uses for check circular dependency in the project tasks and task dependency chain search. */
export class TimelineGraph {
  /** All graph nodes and outgoing edges. Each node determines start or finish one of task. Each edge determines dependency. */
  private graph = new Map<string, GraphNodeInfo>();

  /** All graph nodes and incoming edges. Each node determines start or finish one of task. Each edge determines dependency. */
  private predecessorGraph = new Map<string, GraphNodeInfo>();

  /** Represents  opposite dependency between first/last child nodes and its parent task nodes in strict tasks structure. */
  private coupledNodes = new Map<string, string>();

  private mainTaskId: string;
  private tasks: ProjectTask[];

  constructor(tasks: ProjectTask[]) {
    this.mainTaskId = tasks.find((t) => !t.leadTaskId).id;
    this.tasks = structuredClone(tasks);

    this.initGraph();
    this.initPredecessorGraph();
  }

  /**
   * Checks if a path is allowed between two nodes in the graph.
   *
   * @param beginNode - The starting node of the path.
   * @param endNode - The ending node of the path.
   * @returns True if the path is allowed, false otherwise.
   */
  public checkIsPathAllowed(beginNode: GraphNode, endNode: GraphNode): boolean {
    // There can be only one dependency between two tasks.
    if (
      this.tasks
        .find((t) => t.id === beginNode.id)
        .dependencies.some((d) => d.predecessorId === endNode.id) ||
      this.tasks
        .find((t) => t.id === endNode.id)
        .dependencies.some((d) => d.predecessorId === beginNode.id)
    ) {
      return false;
    }

    // A summary task can only have FS and SS dependencies.
    if (this.checkIsLeadTask(endNode.id) && endNode.type === 'finish') {
      return false;
    }

    // The 'start' node of a task cannot affect the node that affects the 'finish' node of the task.
    if (
      beginNode.type === 'start' &&
      this.checkIfPathExists(endNode, { id: beginNode.id, type: 'finish' })
    ) {
      return false;
    }

    // There is no path from 'endNode' to 'beginNode'.
    if (this.checkIfPathExists(endNode, beginNode)) {
      return false;
    }

    // Within the same hierarchy, a summary/child relationship can only exist between tasks at the same level.
    const allSummaryTasks = this.getAllSummaryTasks(beginNode.id);
    const allChildrenTasks = this.getAllChildTasks(beginNode.id);
    if (
      [...allSummaryTasks, ...allChildrenTasks].some((t) => t.id === endNode.id)
    ) {
      return false;
    }

    // Path is allowed if all checks passed.
    return true;
  }

  /**
   * Determines if a task is allowed to decrease its level considering existing dependencies.
   *
   * @param taskId - The ID of the task to check.
   * @returns A boolean indicating whether the task is allowed to decrease its level.
   */
  public checkIfDecreaseAllowed(taskId: string): boolean {
    const task = this.tasks.find((t) => t.id === taskId);
    const newLeadTask = this.tasks.find(
      (t) => t.indent === task.indent && t.number === task.number - 1,
    );
    if (!newLeadTask) return false;

    // New summary task can only have FS and SS dependencies
    if (
      newLeadTask.dependencies.some(
        (d) =>
          d.type === ProjectTaskDependencyType.finishToFinish ||
          d.type === ProjectTaskDependencyType.startToFinish,
      )
    ) {
      return false;
    }

    const taskStartNode: GraphNode = { id: task.id, type: 'start' };
    const taskEndNode: GraphNode = { id: task.id, type: 'finish' };
    const newLeadTaskStartNode: GraphNode = {
      id: newLeadTask.id,
      type: 'start',
    };

    // Check if the new lead task has dependencies on any task in the task hierarchy
    if (this.checkIfPathExists(taskStartNode, newLeadTaskStartNode)) {
      return false;
    }

    // Check if any task in the task hierarchy depends on the new lead task
    if (this.checkIfPathExists(newLeadTaskStartNode, taskEndNode)) {
      return false;
    }

    return true;
  }

  //TODO: for new algorithm sandbox
  /**
   * Adjusts the positions of tasks after increasing their level in the hierarchy.
   *
   * This method finds the task and its last lead task, then checks the lead task nodes.
   *
   * @param taskId The ID of the task whose level has been increased.
   * @returns An object containing the updated list of project tasks.
   */
  public moveTasksAfterIncreaseLevel(taskId: string): {
    projectTask: ProjectTask[];
  } {
    const task = this.tasks.find((t) => t.id === taskId);
    const lastLeadTask = this.tasks.find(
      (t) => t.indent === task.indent && t.number === task.number - 1,
    );

    this.checkLeadTaskNodes(lastLeadTask.id);

    return { projectTask: this.tasks };
  }

  //TODO: for new algorithm sandbox
  /**
   * Adjusts the positions of tasks after decreasing their level in the hierarchy.
   *
   * This method finds the task and its lead task, then checks and adjusts the start date of the task
   * based on the minimum start date of the lead task's predecessors. If the task's start date is earlier
   * than the minimum date, it moves the task to align with the minimum date. Otherwise, it checks the lead task nodes.
   *
   * @param taskId The ID of the task whose level has been decreased.
   * @returns An object containing the updated list of project tasks.
   */
  public moveTasksAfterDecreaseLevel(taskId: string): {
    projectTask: ProjectTask[];
  } {
    const task = this.tasks.find((t) => t.id === taskId);
    const leadTask = this.tasks.find((t) => t.id === task.leadTaskId);

    const taskStartNode: GraphNode = { id: task.id, type: 'start' };
    const leadTaskStartNode: GraphNode = { id: leadTask.id, type: 'start' };

    const taskStartDate = DateTime.fromISO(task.startDate);

    const leadTaskStartNodePredecessorDates = this.predecessorGraph
      .get(this.getGraphKey(leadTaskStartNode))
      .dependencies.filter((d) => !d.sticky)
      .map((dep) =>
        dep.node.type === 'start'
          ? DateTime.fromISO(dep.task.startDate)
          : DateTime.fromISO(dep.task.endDate).plus({ days: 1 }),
      );
    const minDate = DateTime.min(...leadTaskStartNodePredecessorDates);

    if (taskStartDate < minDate) {
      const diff = minDate.diff(taskStartDate, 'days');
      this.moveNode(this.getGraphKey(taskStartNode), diff.days);
    } else {
      this.checkLeadTaskNodes(leadTask.id);
    }

    return { projectTask: this.tasks };
  }

  //TODO: for new algorithm sandbox
  /**
   * Updates a task with new fields and adjusts the timeline graph accordingly.
   *
   * @param taskId The ID of the task to update.
   * @param fieldsToUpdate An object containing the fields to update and their new values.
   * @returns An object containing the updated tasks.
   */
  public updateTask(
    taskId: string,
    fieldsToUpdate: Partial<ProjectTask>,
  ): { projectTask: ProjectTask[] } {
    const updatedTasks = { projectTask: this.tasks };
    const targetTask = this.tasks.find((t) => t.id === taskId);

    // Update task end date and adjust the timeline graph
    if (fieldsToUpdate.endDate) {
      const targetTaskEndDate = DateTime.fromISO(targetTask.endDate);
      const targetTaskNewEndDate = DateTime.fromISO(fieldsToUpdate.endDate);
      const offset = targetTaskNewEndDate.diff(targetTaskEndDate, 'days').days;
      this.moveNode('finish-' + taskId, offset, false);
    }

    // Update task start date and adjust the timeline graph
    if (fieldsToUpdate.startDate) {
      const targetTaskStartDate = DateTime.fromISO(targetTask.startDate);
      const targetTaskNewStartDate = DateTime.fromISO(fieldsToUpdate.startDate);
      const offset = targetTaskNewStartDate.diff(
        targetTaskStartDate,
        'days',
      ).days;
      this.moveNode('start-' + taskId, offset, !!fieldsToUpdate.duration);
    }

    // Update task dependencies and adjust the timeline graph
    if (fieldsToUpdate.dependencies) {
      const task = this.tasks.find((t) => t.id === taskId);

      if (!fieldsToUpdate.dependencies.length) {
        task.dependencies.forEach((dependency) =>
          this.removeDependency(taskId, dependency),
        );
        this.initGraph();
        this.initPredecessorGraph();
        return updatedTasks;
      }

      // Find a new dependency to add
      const newDependency = fieldsToUpdate.dependencies.find(
        (dep) =>
          !task.dependencies.find(
            (d) => d.predecessorId === dep.predecessorId,
          ) ||
          task.dependencies.some(
            (d) => d.predecessorId === dep.predecessorId && d.type !== dep.type,
          ),
      );

      // Find a dependency to remove
      const dependencyToRemove = task.dependencies.find(
        (d) =>
          d.predecessorId === newDependency?.predecessorId ||
          !fieldsToUpdate.dependencies.find(
            (dep) => dep.predecessorId === d.predecessorId,
          ),
      );

      // Remove the dependency if found
      if (dependencyToRemove) {
        this.removeDependency(taskId, dependencyToRemove);
      }

      // Add the new dependency if found
      if (newDependency) {
        this.addDependency(taskId, newDependency);
      }
    }

    return updatedTasks;
  }

  /**
   * Checks if a path exists between two nodes in the graph.
   *
   * @param beginNode - Beginning node.
   * @param endNode - Ending node.
   * @returns True if a path exists, false otherwise.
   */
  private checkIfPathExists(beginNode: GraphNode, endNode: GraphNode): boolean {
    if (beginNode.id === endNode.id) return true;

    // Prevent dependencies with main task
    if ([beginNode, endNode].some((n) => n.id === this.mainTaskId)) {
      return true;
    }

    const beginNodeKey = this.getGraphKey(beginNode);
    const endNodeKey = this.getGraphKey(endNode);

    let pathExist = false;
    // Map is used to mark the current node as visited
    const visited = new Map();
    const stack = [];

    // Push the current node to the stack which stores the result
    stack.push(beginNodeKey);

    // Traverse through the nodes until the stack is empty
    while (stack.length > 0 && !pathExist) {
      const nodeKey = stack.pop();

      // Mark the task as visited
      visited.set(nodeKey, true);

      // For take into account task duration preserving, needs to check start node dependencies.
      const nodeInfo = this.graph.get(nodeKey);
      let checkingDependencies = nodeInfo.dependencies;
      if (nodeInfo.nodeType === 'finish') {
        const startNode: GraphNode = {
          id: nodeInfo.task.id,
          type: 'start',
        };
        const taskSecondNodeInfo = this.graph.get(this.getGraphKey(startNode));
        if (!visited.get(this.getGraphKey(startNode))) {
          checkingDependencies = [
            ...checkingDependencies,
            ...taskSecondNodeInfo.dependencies.filter(
              (d) => !d.sticky && d.node.id !== nodeInfo.task.id,
            ),
          ];
        }
      }

      // Recur for all the nodes adjacent (by dependency) to this tasks
      checkingDependencies.forEach((dep) => {
        // Break the cycle if circularity already found
        if (pathExist) {
          return;
        }
        // Path exist check
        if (this.getGraphKey(dep.node) === endNodeKey) {
          pathExist = true;
          return;
        }
        // If the node are not visited
        if (!visited.get(this.getGraphKey(dep.node))) {
          // Push the current node to the stack which stores the result
          stack.push(this.getGraphKey(dep.node));
        }
      });
    }

    return pathExist;
  }

  /**
   * Adds a dependency to a task and updates the timeline graph accordingly.
   *
   * @param taskId The ID of the task to which the dependency is being added.
   * @param dependency The dependency to be added.
   */
  private addDependency(
    taskId: string,
    dependency: ProjectTaskDependency,
  ): void {
    // Find the task by ID and add the dependency to its dependencies array.
    this.tasks.find((t) => t.id === taskId).dependencies.push(dependency);

    // Re-initialize the graph, predecessor graph, and coupled nodes to reflect the new dependency.
    // TODO: Should to directly update the graphs
    this.initGraph();
    this.initPredecessorGraph();

    // Determine the graph node key based on the dependency type.
    const graphNodeKey =
      dependency.type === ProjectTaskDependencyType.startToStart ||
      dependency.type === ProjectTaskDependencyType.startToFinish
        ? `start-${dependency.predecessorId}`
        : `finish-${dependency.predecessorId}`;

    // Resolve dependencies for the affected node.
    this.resolveNodeDependencies(graphNodeKey);
  }

  /**
   * Removes a dependency from a task and updates the timeline graph accordingly.
   *
   * @param taskId The ID of the task from which the dependency is being removed.
   * @param dependency The dependency to be removed.
   */
  private removeDependency(
    taskId: string,
    dependency: ProjectTaskDependency,
  ): void {
    // Find the task by ID and remove the dependency from its dependencies array.
    const task = this.tasks.find((t) => t.id === taskId);
    task.dependencies = task.dependencies.filter(
      (d) => d.predecessorId !== dependency.predecessorId,
    );

    // Determine the graph node key based on the dependency type.
    const graphNodeKey =
      dependency.type === ProjectTaskDependencyType.finishToFinish ||
      dependency.type === ProjectTaskDependencyType.startToFinish
        ? `start-${dependency.predecessorId}`
        : `finish-${dependency.predecessorId}`;
    // Remove the dependency from the graph node.
    const nodeInfo = this.graph.get(graphNodeKey);
    nodeInfo.dependencies = nodeInfo.dependencies.filter(
      (d) => d.node.id !== taskId,
    );

    // Determine the predecessor graph node key based on the dependency type.
    const predecessorGraphNodeKey =
      dependency.type === ProjectTaskDependencyType.finishToFinish ||
      dependency.type === ProjectTaskDependencyType.startToFinish
        ? `finish-${taskId}`
        : `start-${taskId}`;
    // Remove the dependency from the predecessor graph node.
    const predecessorNodeInfo = this.predecessorGraph.get(
      predecessorGraphNodeKey,
    );
    predecessorNodeInfo.dependencies = predecessorNodeInfo.dependencies.filter(
      (d) => d.node.id !== dependency.predecessorId,
    );
  }

  /**
   * Moves a node in the timeline graph and updates the dependencies accordingly.
   *
   * @param nodeName The name of the node to be moved.
   * @param offset The offset by which the node is to be moved.
   * @param preserveDuration If true, the duration of the task is preserved. Default is true.
   */
  private moveNode(nodeName: string, offset: number, preserveDuration = true) {
    if (!offset) return;

    // Retrieve node information from the graph
    const nodeInfo = this.graph.get(nodeName);

    const isLeadTask = this.checkIsLeadTask(nodeInfo.task.id);
    // If the node is a start node of a lead task and preserving duration, move the lead task
    if (isLeadTask && preserveDuration && nodeInfo.nodeType === 'start') {
      this.moveLeadTask(nodeInfo.task.id, offset);
      // Check lead task nodes if the current task has a lead task ID
      if (nodeInfo.task.leadTaskId) {
        this.checkLeadTaskNodes(nodeInfo.task.leadTaskId);
      }
      // Exit after moving the lead task
      return;
    }

    // Handle negative offset adjustments
    if (offset < 0) {
      // Calculate the maximum negative offset for the node
      let maxNegativeOffset = this.getMaxNegativeOffset(nodeName);
      // If preserving duration, consider the second node type (start or finish)
      if (preserveDuration) {
        const secondNodeType =
          nodeInfo.nodeType === 'finish' ? 'start' : 'finish';
        // Calculate the maximum negative offset for the second node type
        const secondNodeMaxNegativeOffset = this.getMaxNegativeOffset(
          this.getGraphKey({ id: nodeInfo.task.id, type: secondNodeType }),
        );
        // Update maxNegativeOffset if the second node's offset is greater
        if (maxNegativeOffset)
          maxNegativeOffset = Math.max(
            maxNegativeOffset,
            secondNodeMaxNegativeOffset,
          );
      }
      // Adjust offset to the maximum negative offset if it's less than the calculated max
      if (maxNegativeOffset !== -Infinity && offset < maxNegativeOffset) {
        offset = maxNegativeOffset;
      }
    }

    // If the node is a finish node and preserving duration, move the corresponding start node
    if (nodeInfo.nodeType === 'finish' && preserveDuration) {
      this.moveNode('start-' + nodeInfo.task.id, offset, true);
      // Exit after moving the start node
      return;
    }

    // Determine the date field to update based on the node type
    const targetNodeDateField = this.getNodeCorrespondingDateKey(
      nodeInfo.nodeType,
    );
    // Convert the task date to a DateTime object
    const nodeTaskDate = DateTime.fromISO(nodeInfo.task[targetNodeDateField]);
    // Move the target node date by the offset
    nodeInfo.task[targetNodeDateField] = nodeTaskDate
      .plus({ days: offset })
      .toISODate();

    // Check if the task has a lead task ID and update lead task nodes if necessary
    if (nodeInfo.task.leadTaskId) {
      this.checkLeadTaskNodes(nodeInfo.task.leadTaskId);
    }

    // If preserving duration and the node is a start node, move the corresponding finish node
    if (preserveDuration && nodeInfo.nodeType === 'start') {
      this.moveNode('finish-' + nodeInfo.task.id, offset, false);
    }

    // Resolve dependencies for the moved node
    this.resolveNodeDependencies(nodeName);
  }

  /**
   * Moves a lead task and its children in the timeline graph and updates the dependencies accordingly.
   *
   * @param taskId The ID of the lead task to be moved.
   * @param offset The offset by which the lead task is to be moved.
   */
  /**
   * Moves a lead task and its children in the timeline graph and updates the dependencies accordingly.
   *
   * @param taskId The ID of the lead task to be moved.
   * @param offset The offset by which the lead task is to be moved.
   */
  private moveLeadTask(taskId: string, offset: number): void {
    // Get all child tasks of the lead task and their node names
    const childrenNodeNames = this.getAllChildTasks(taskId).flatMap((task) =>
      this.getNodeNamePair(task.id),
    );
    // Combine the node names of the lead task and its children for getting max offset
    const handleNodes = [...this.getNodeNamePair(taskId), ...childrenNodeNames];

    // If the offset is negative, calculate the maximum negative offset
    if (offset < 0) {
      const maxNegativeOffset = Math.max(
        ...handleNodes.flatMap((node) =>
          this.getMaxNegativeOffset(node, handleNodes),
        ),
      );

      // If the calculated maximum negative offset is greater than the offset, set the offset to the maximum negative offset
      if (maxNegativeOffset !== -Infinity && offset < maxNegativeOffset) {
        offset = maxNegativeOffset;
      }
    }

    // Move each node in the handleNodes array by the offset
    handleNodes.forEach((nodeName) => {
      const node = this.graph.get(nodeName);
      const dateKey = this.getNodeCorrespondingDateKey(node.nodeType);
      const nodeTaskDate = DateTime.fromISO(node.task[dateKey]);
      node.task[dateKey] = nodeTaskDate.plus({ days: offset }).toISODate();
    });

    // Resolve dependencies for each node in the handleNodes array
    handleNodes.forEach((nodeName) => {
      //TODO: filter dependencies in branch
      this.resolveNodeDependencies(nodeName);
    });
  }

  /**
   * Resolves the dependencies of a node in the timeline graph.
   *
   * @param nodeName The name of the node whose dependencies are to be resolved.
   */
  private resolveNodeDependencies(nodeName: string): void {
    const nodeInfo = this.graph.get(nodeName);
    const targetNodeDateField = this.getNodeCorrespondingDateKey(
      nodeInfo.nodeType,
    );

    // Filter out dependencies where the dependent node's task id is not equal to the current node's task id
    nodeInfo.dependencies
      .filter(
        (d) =>
          this.graph.get(this.getGraphKey(d.node)).task.id !== nodeInfo.task.id,
      )
      // For each dependency, check if there is a dependency violation
      .forEach((d) => {
        const dependentNode = this.graph.get(this.getGraphKey(d.node));
        const dateField = this.getNodeCorrespondingDateKey(
          dependentNode.nodeType,
        );

        const dependentNodeTaskDate = DateTime.fromISO(
          dependentNode.task[dateField],
        );
        let diff = dependentNodeTaskDate.diff(
          DateTime.fromISO(nodeInfo.task[targetNodeDateField]),
          'days',
        ).days;
        const isDependencyViolation = diff < d.min;
        // If there is a dependency violation, calculate the difference and move the node. Don't preserve duration to/from lead tasks (sticky dependencies)
        if (isDependencyViolation) {
          diff = d.min - diff;
          this.moveNode(this.getGraphKey(d.node), diff, !d.sticky);
        }
      });
  }

  /**
   * Adjusts the start and end dates of a task based on the earliest start date and latest end date of its children tasks.
   *
   * This method iterates through all tasks to find the task with the specified taskId and its children tasks. It then calculates
   * the earliest start date and latest end date among the children tasks. If the start or end date of the task does not match
   * the earliest start date or latest end date of its children, respectively, the task's start or end date is adjusted accordingly.
   *
   * @param taskId The ID of the task whose children's dates are to be checked.
   */
  private checkLeadTaskNodes(taskId: string): void {
    const task = this.tasks.find((t) => t.id === taskId);
    const childrenTasks = this.tasks.filter((t) => t.leadTaskId === task.id);
    const childStartDates = childrenTasks.map((t) =>
      DateTime.fromISO(t.startDate),
    );
    const minDate = DateTime.min(...childStartDates);
    const nodeStartDate = DateTime.fromISO(task.startDate);
    if (minDate !== nodeStartDate) {
      const offset = minDate.diff(nodeStartDate, 'days').days;
      this.moveNode(`start-${task.id}`, offset, false);
    }

    const childEndDates = childrenTasks.map((t) => DateTime.fromISO(t.endDate));
    const maxDate = DateTime.max(...childEndDates);
    const nodeEndDate = DateTime.fromISO(task.endDate);
    if (maxDate !== nodeEndDate) {
      const offset = maxDate.diff(nodeEndDate, 'days').days;
      this.moveNode(`finish-${task.id}`, offset, false);
    }
  }

  /**
   * This method calculates the maximum negative offset for a given node.
   *
   * @param nodeName The name of the node for which the maximum negative offset is to be calculated.
   * @param excludedNodeNames An optional array of node names to be excluded from the calculation.
   * @returns The maximum negative offset for the node, or null if no offset is found.
   */
  private getMaxNegativeOffset(
    nodeName: string,
    excludedNodeNames?: string[],
  ): number | null {
    // Retrieve the predecessors information for the given node name
    const nodePredecessorsInfo = this.predecessorGraph.get(nodeName);

    // Filter and map dependencies to get dates that are not sticky, not self-referential, and not excluded
    const dates = nodePredecessorsInfo.dependencies
      .filter((d) => !d.sticky) // Exclude sticky dependencies
      .filter((d) => d.task.id !== nodePredecessorsInfo.task.id) // Exclude self-referential dependencies
      .filter(
        (d) => !excludedNodeNames?.some((name) => name.includes(d.task.id)), // Exclude dependencies to nodes in the excluded list
      )
      .flatMap((d) => {
        // Determine the date field based on the node type
        const dateField = this.getNodeCorrespondingDateKey(d.node.type);
        // Convert the date string to a DateTime object
        let date = DateTime.fromISO(
          this.tasks.find((t) => t.id === d.node.id)[dateField],
        );
        // If there's a minimum offset, add it to the date
        if (d.min) {
          date = date.plus({ days: d.min });
        }
        return date;
      });

    // Get the date for the current node
    const nodeDate = DateTime.fromISO(
      nodePredecessorsInfo.task[
        this.getNodeCorrespondingDateKey(nodePredecessorsInfo.nodeType)
      ],
    );

    // Calculate the maximum offset due to sticky dependencies
    const maxOffsetByStickyNodes = Math.max(
      ...nodePredecessorsInfo.dependencies
        .filter((d) => d.sticky) // Filter for sticky dependencies
        .filter(
          (d) => !excludedNodeNames?.some((name) => name.includes(d.task.id)), // Exclude dependencies to nodes in the excluded list
        )
        .flatMap((dep) => {
          // Get the date for the dependent node
          const depNodeDate = DateTime.fromISO(
            dep.task[this.getNodeCorrespondingDateKey(dep.node.type)],
          );

          // Calculate the offset between the dependent node date and the current node date
          const offset = depNodeDate.diff(nodeDate, 'days').days;
          // Add the maximum negative offset of the dependent node to the offset
          return this.getMaxNegativeOffset(this.getGraphKey(dep.node)) + offset;
        }),
    );

    // If there are no dates to consider, return the maximum offset due to sticky nodes
    if (!dates.length) return maxOffsetByStickyNodes;

    // Find the maximum date among the filtered dates
    const maxDate = DateTime.max(...dates);

    // Return the maximum offset, considering both the maximum date offset and the maximum offset due to sticky nodes
    return Math.max(
      maxDate.diff(nodeDate, 'days').days,
      maxOffsetByStickyNodes,
    );
  }

  /** Initializes the graph structure for the timeline. */
  private initGraph(): void {
    // Create a Map to store tasks for O(1) lookup
    const taskMap = new Map(this.tasks.map((task) => [task.id, task]));

    // Create a Map to store child tasks for each parent
    const childrenMap = new Map<string, ProjectTask[]>();

    // Create a Map to store dependent tasks for each task
    const dependentTasksMap = new Map<string, ProjectTask[]>();

    this.tasks.forEach((task) => {
      if (task.leadTaskId) {
        if (!childrenMap.has(task.leadTaskId)) {
          childrenMap.set(task.leadTaskId, []);
        }
        childrenMap.get(task.leadTaskId).push(task);
      }

      task.dependencies?.forEach((dep) => {
        if (!dependentTasksMap.has(dep.predecessorId)) {
          dependentTasksMap.set(dep.predecessorId, []);
        }
        dependentTasksMap.get(dep.predecessorId).push(task);
      });
    });

    this.tasks.forEach((task) => {
      const startNode: GraphNode = { id: task.id, type: 'start' };
      const finishNode: GraphNode = { id: task.id, type: 'finish' };

      const startNodeDeps: GraphDependency[] = [];
      const finishNodeDeps: GraphDependency[] = [];

      const childrenTasks = childrenMap.get(task.id) || [];

      childrenTasks.forEach((childTask) => {
        const dep = {
          node: {
            id: childTask.id,
            type: 'start' as GraphNodeType,
          },
          min: 0,
          sticky: true,
          task: childTask,
        };
        startNodeDeps.push(dep);
      });

      if (task.leadTaskId) {
        const leadTask = taskMap.get(task.leadTaskId);
        const dep = {
          node: {
            id: task.leadTaskId,
            type: 'finish' as GraphNodeType,
          },
          min: 0,
          sticky: true,
          task: leadTask,
        };
        finishNodeDeps.push(dep);
      }

      startNodeDeps.unshift({
        node: {
          id: task.id,
          type: 'finish' as GraphNodeType,
        },
        min: 0,
        task,
      });

      const dependentTasks = dependentTasksMap.get(task.id) || [];

      dependentTasks.forEach((dependentTask) => {
        dependentTask.dependencies.forEach((dep) => {
          if (dep.predecessorId !== task.id) return;
          switch (dep.type) {
            case ProjectTaskDependencyType.finishToStart:
            case ProjectTaskDependencyType.startToStart:
            case ProjectTaskDependencyType.finishToFinish:
            case ProjectTaskDependencyType.startToFinish: {
              const graphDep = {
                node: {
                  id: dependentTask.id,
                  type:
                    dep.type === ProjectTaskDependencyType.finishToStart ||
                    dep.type === ProjectTaskDependencyType.startToStart
                      ? 'start'
                      : ('finish' as GraphNodeType),
                },
                min:
                  dep.type === ProjectTaskDependencyType.finishToStart ? 1 : 0,
                task: dependentTask,
              };
              (dep.type === ProjectTaskDependencyType.finishToStart ||
              dep.type === ProjectTaskDependencyType.finishToFinish
                ? finishNodeDeps
                : startNodeDeps
              ).push(graphDep);
              break;
            }
          }
        });
      });

      const startNodeInfo = {
        task,
        nodeType: 'start' as GraphNodeType,
        dependencies: startNodeDeps,
      };
      const finishNodeInfo = {
        task,
        nodeType: 'finish' as GraphNodeType,
        dependencies: finishNodeDeps,
      };

      this.graph.set(this.getGraphKey(startNode), startNodeInfo);
      this.graph.set(this.getGraphKey(finishNode), finishNodeInfo);
    });
  }

  /**
   * Checks if a task is a lead task.
   *
   * @param taskId - The ID of the task to check.
   * @returns True if the task is a lead task, false otherwise.
   */
  private checkIsLeadTask(taskId: string): boolean {
    return this.tasks.some((t) => t.leadTaskId === taskId);
  }

  /**
   * Generates a pair of node names for a given task ID, one for the start and one for the finish.
   *
   * @param taskId - The ID of the task for which to generate the node names.
   * @returns An array containing two strings: 'start-' + taskId and 'finish-' + taskId.
   */
  private getNodeNamePair(taskId: string): [string, string] {
    return ['start-' + taskId, 'finish-' + taskId];
  }

  /**
   * Generates a unique key for a given GraphNode.
   *
   * This method combines the type and id of a GraphNode to create a unique string identifier.
   *
   * @param node - The GraphNode for which to generate the key.
   * @returns A string representing the unique key for the given GraphNode.
   */
  private getGraphKey(node: GraphNode): string {
    return `${node.type}-${node.id}`;
  }

  /**
   * Gets the corresponding date key for a given node type.
   *
   * @param type - The type of the graph node ('start' or 'finish').
   * @returns The corresponding date key ('startDate' for 'start' nodes, 'endDate' for 'finish' nodes).
   */
  private getNodeCorrespondingDateKey(type: GraphNodeType): string {
    return type === 'finish' ? 'endDate' : 'startDate';
  }

  /** Initializes the predecessor graph for the timeline. */
  private initPredecessorGraph(): void {
    const getTask = (id: string) => this.tasks.find((t) => t.id === id);
    this.tasks.forEach((task) => {
      const startNode: GraphNode = { id: task.id, type: 'start' };
      const finishNode: GraphNode = { id: task.id, type: 'finish' };

      const startNodeDeps: GraphDependency[] = [];

      //SS dependency from lead task
      if (task.leadTaskId) {
        const predecessorTask = getTask(task.leadTaskId);
        const dep = {
          node: {
            id: task.leadTaskId,
            type: 'start' as GraphNodeType,
          },
          min: 0,
          sticky: true,
          task: predecessorTask,
        };
        startNodeDeps.push(dep);
      }

      //FF dependencies from children tasks
      const finishNodeDeps: GraphDependency[] = this.tasks
        .filter((t) => t.leadTaskId === task.id)
        .map((t) => {
          const dep = {
            node: {
              id: t.id,
              type: 'finish' as GraphNodeType,
            },
            min: 0,
            sticky: true,
            task: t,
          };
          return dep;
        });

      //FS dependency from self start date
      finishNodeDeps.unshift({
        node: {
          id: task.id,
          type: 'start' as GraphNodeType,
        },
        min: 0,
        task,
      });

      task.dependencies?.forEach((dep) => {
        switch (dep.type) {
          case ProjectTaskDependencyType.finishToStart: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'finish' as GraphNodeType,
              },
              min: 1,
              task: predecessorTask,
            };
            startNodeDeps.push(graphDep);
            break;
          }
          case ProjectTaskDependencyType.startToStart: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'start' as GraphNodeType,
              },
              min: 0,
              task: predecessorTask,
            };
            startNodeDeps.push(graphDep);
            break;
          }

          case ProjectTaskDependencyType.finishToFinish: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'finish' as GraphNodeType,
              },
              min: 0,
              task: predecessorTask,
            };
            finishNodeDeps.push(graphDep);
            break;
          }
          case ProjectTaskDependencyType.startToFinish: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'start' as GraphNodeType,
              },
              min: 0,
              task: predecessorTask,
            };
            finishNodeDeps.push(graphDep);
            break;
          }
        }
      });

      const startNodeInfo = {
        task,
        nodeType: 'start' as GraphNodeType,
        dependencies: startNodeDeps,
      };
      const finishNodeInfo = {
        task,
        nodeType: 'finish' as GraphNodeType,
        dependencies: finishNodeDeps,
      };

      this.predecessorGraph.set(this.getGraphKey(startNode), startNodeInfo);
      this.predecessorGraph.set(this.getGraphKey(finishNode), finishNodeInfo);
    });
  }

  /**
   * Returns all child task chain in the flat array.
   *
   * @param leadTaskId - id of the task for which searches child tasks
   * @returns all child tasks
   */
  private getAllChildTasks(leadTaskId: string): ProjectTask[] {
    if (!this.checkIsLeadTask(leadTaskId)) {
      return [];
    }

    const findFirstLevelChildren = (taskId: string) =>
      this.tasks.filter((t) => t.leadTaskId === taskId);

    return findFirstLevelChildren(leadTaskId).flatMap((childTask) => [
      childTask,
      ...this.getAllChildTasks(childTask.id),
    ]);
  }

  /**
   * Returns all summary task chain in the flat array.
   *
   * @param taskId - id of the task for which searches summary tasks.
   * @param targetTasks - project tasks array for search (default uses array from data service).
   * @param excludeMain - determines whether to exclude main task from resulting tasks.
   * @returns all parent tasks.
   */
  private getAllSummaryTasks(
    taskId: string,
    excludeMain = false,
  ): ProjectTask[] {
    const task = this.tasks.find((t) => t.id === taskId);
    if (!task.leadTaskId) {
      return [];
    }

    const findFirstLevelSummaryTask = (childTask: ProjectTask) => [
      this.tasks.find((t) => t.id === childTask.leadTaskId),
    ];

    let summaryTasks = findFirstLevelSummaryTask(task).flatMap(
      (summaryTask) => [
        summaryTask,
        ...this.getAllSummaryTasks(summaryTask.id),
      ],
    );
    if (excludeMain) {
      summaryTasks = summaryTasks.filter((st) => !!st.leadTaskId);
    }
    return summaryTasks;
  }
}

export interface GraphNode {
  id: string;
  type: GraphNodeType;
}

export interface GraphNodeInfo {
  nodeType: GraphNodeType;
  task: ProjectTask;
  dependencies: GraphDependency[];
}

export interface GraphDependency {
  node: GraphNode;
  min: number; // Determines min dependency offset (1 just for FS dependencies)
  sticky?: boolean; // Determines sticky dependency between boundary children and lead task. It means that parent task must fit to their children tasks.
  task: ProjectTask;
}

export type GraphNodeType = 'start' | 'finish';
