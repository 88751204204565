<div class="row" [formGroup]="form">
  <div class="col-5">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.supervisor' | translate }}</label>

      <wp-user-box
        formControlName="supervisor"
        placeholder="{{ 'shared2.props.supervisor' | translate }}"
      />
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.department' | translate }}</label>
      <tmt-department-box
        formControlName="department"
        placeholder="{{ 'shared2.props.department' | translate }}"
      />
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.location' | translate }}</label>
      <wp-select-box
        collection="Locations"
        formControlName="location"
        placeholder="{{ 'shared2.props.location' | translate }}"
      />
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.resourcePool' | translate }}</label>
      <tmt-hierarchical-box
        collection="ResourcePools"
        parentIdKey="leadResourcePoolId"
        formControlName="resourcePool"
        placeholder="{{ 'shared2.props.resourcePool' | translate }}"
      />
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.legalEntity' | translate }}</label>
      <wp-select-box
        collection="LegalEntities"
        formControlName="legalEntity"
        placeholder="{{ 'shared2.props.legalEntity' | translate }}"
      />
    </div>
  </div>
  <div class="col-5">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.level' | translate }}</label>
      <wp-select-box
        #cascadeControl
        formControlName="level"
        collection="Levels"
        placeholder="{{ 'shared2.props.level' | translate }}"
      />
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.grade' | translate }}</label>
      <wp-select-box
        #cascadeControl
        formControlName="grade"
        collection="Grades"
        [query]="{ select: ['id', 'name', 'levelId'] }"
        placeholder="{{ 'shared2.props.grade' | translate }}"
      />
    </div>
    <div class="form-group">
      <label>{{ 'shared2.props.role' | translate }}</label>
      <wp-select-box
        #cascadeControl
        collection="Roles"
        formControlName="role"
        placeholder="{{ 'shared2.props.role' | translate }}"
      />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.competence' | translate }}</label>
      <wp-select-box
        #cascadeControl
        collection="Competences"
        formControlName="competence"
        placeholder="{{ 'shared2.props.competence' | translate }}"
      />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.kind' | translate }}</label>
      <wp-select-box
        formControlName="kindId"
        [isIdMode]="true"
        [directoryId]="systemDirectory.resourceKind"
        placeholder="{{ 'shared2.props.kind' | translate }}"
      />
    </div>
  </div>
</div>
