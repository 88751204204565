import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { DEBUG_APP_STATES } from './debug-app.states';
import { DebugAppComponent } from './debug-app.component';
import { StatusPageComponent } from './status-page/status-page.component';
import { HierarchicalBoxTestComponent } from './hierarchical-box-test/hierarchical-box-test.component';
import { TextBoxTestComponent } from './text-box-test/text-box-test.component';
import { NumberBoxTestComponent } from './number-box-test/number-box-test.component';
import { UserBoxTestComponent } from './user-box-test/user-box-test.component';
import { MultilineTextBoxTestComponent } from './multiline-text-box-test/multiline-text-box-test.component';
import { SelectBoxTestComponent } from './select-box-test/select-box-test.component';
import { DateBoxTestComponent } from './date-box-test/date-box-test.component';
import { DatePeriodBoxTestComponent } from './date-period-box-test/date-period-box-test.component';
import { TaskBoxTestComponent } from './task-box-test/task-box-test.component';
import { CurrencyBoxTestComponent } from './currency-box-test/currency-box-test.component';
import { GridTestComponent } from './grid-test/grid-test.component';
import { DurationBoxTestComponent } from './duration-box-test/duration-box-test.component';
import { ProjectCardSharedModule } from 'src/app/projects/card/shared/project-card-shared.module';
import { PredecessorBoxTestComponent } from 'src/app/debug-app/predecessor-box-test/predecessor-box-test.component';
import { Grid2TestComponent } from 'src/app/debug-app/grid2-test/grid2-test.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { Grid2TestToolbarComponent } from 'src/app/debug-app/grid2-test/grid2-test-toolbar/grid2-test-toolbar.component';
import { Grid2TestRightSideTableComponent } from 'src/app/debug-app/grid2-test/grid2-test-right-side-table/grid2-test-right-side-table.component';
import { FormHelperTestComponent } from 'src/app/debug-app/form-helper-test/form-helper-test.component';
import { RichEditorBoxTestComponent } from 'src/app/debug-app/rich-editor-box-test/rich-editor-box-test.component';
import { RichEditorBoxComponent } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box.component';

@NgModule({
  declarations: [
    DebugAppComponent,
    StatusPageComponent,
    HierarchicalBoxTestComponent,
    TextBoxTestComponent,
    UserBoxTestComponent,
    NumberBoxTestComponent,
    MultilineTextBoxTestComponent,
    SelectBoxTestComponent,
    DateBoxTestComponent,
    DatePeriodBoxTestComponent,
    TaskBoxTestComponent,
    CurrencyBoxTestComponent,
    GridTestComponent,
    DurationBoxTestComponent,
    PredecessorBoxTestComponent,
    Grid2TestComponent,
    Grid2TestToolbarComponent,
    Grid2TestRightSideTableComponent,
    FormHelperTestComponent,
    RichEditorBoxTestComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    Grid2Module,
    ProjectCardSharedModule,
    RichEditorBoxComponent,
    UIRouterModule.forChild({
      states: DEBUG_APP_STATES,
    }),
  ],
})
export class DebugAppModule {}
