import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Board } from 'src/app/settings-app/boards/model/board.model';

@Injectable()
export class BoardCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private nameSubject = new BehaviorSubject<string>('');
  public name$ = this.nameSubject.asObservable();

  private boardSubject = new ReplaySubject<Board>();
  public board$ = this.boardSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId: string,
    private dataService: DataService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
  ) {
    this.load();
  }

  /** Loads board data. */
  public load(): void {
    this.state$.next(CardState.Loading);

    const query = {
      select: ['id', 'name', 'entityType', 'isActive', 'navigations'],
      expand: {
        team: {
          select: [
            'id',
            'boardId',
            'userId',
            'groupId',
            'permissionSetId',
            'role',
          ],
          expand: {
            user: { select: ['id', 'name'] },
            group: { select: ['id', 'name'] },
            permissionSet: { select: ['id', 'name'] },
          },
        },
      },
    };

    this.dataService
      .collection('Boards')
      .entity(this.entityId)
      .get<Board>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (board: Board) => {
          this.nameSubject.next(board.name);
          this.boardSubject.next(board);

          this.navigationService.addRouteSegment({
            id: board.id,
            title: board.name,
          });
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Updates board name subject.
   *
   * @param name new name.
   */
  public updateName(name: string): void {
    this.nameSubject.next(name);
  }
}
