<wp-loading-indicator [loading]="service.isLoading()" />
<wp-data-not-saved-indicator [form]="service.contactForm" />

<form
  class="form"
  [hidden]="service.isLoading()"
  [formGroup]="service.contactForm"
  novalidate
  autocomplete="off"
>
  <div class="row mt-2">
    <div class="row">
      <div class="col-2">
        <div class="form-group" tmtIndicator>
          <label>{{ 'shared2.props.firstName' | translate }}</label>
          <wp-text-box
            formControlName="firstName"
            placeholder="{{ 'shared2.props.firstName' | translate }}"
          />
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.lastName' | translate }}</label>
          <wp-text-box
            formControlName="lastName"
            placeholder="{{ 'shared2.props.lastName' | translate }}"
          />
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.patronymic' | translate }}</label>
          <wp-text-box
            formControlName="patronymic"
            placeholder="{{ 'shared2.props.patronymic' | translate }}"
          />
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <label class="control-label">{{ 'shared2.props.organization' | translate }}</label>
          <wp-select-box
            formControlName="organizationId"
            collection="Organizations"
            [isIdMode]="true"
            placeholder="{{ 'shared2.props.organization' | translate }}"
          />
        </div>

        <div class="form-group">
          <label class="control-label">{{ 'shared2.props.role' | translate }}</label>
          <wp-select-box
            formControlName="roleId"
            [isIdMode]="true"
            [directoryId]="systemDirectory.contactRole"
            placeholder="{{ 'shared2.props.role' | translate }}"
          />
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.position' | translate }}</label>
          <wp-text-box
            formControlName="position"
            placeholder="{{ 'shared2.props.position' | translate }}"
          />
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <label>{{ 'shared2.props.email' | translate }}</label>
          <wp-text-box
            formControlName="email"
            placeholder="{{ 'shared2.props.email' | translate }}"
          />
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.mobilePhone' | translate }}</label>
          <wp-text-box
            formControlName="mobilePhone"
            placeholder="{{ 'shared2.props.mobilePhone' | translate }}"
          />
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.phone' | translate }}</label>
          <wp-text-box
            formControlName="phone"
            placeholder="{{ 'shared2.props.phone' | translate }}"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="col-4">
      <div class="form-group">
        <label>{{ 'shared2.props.description' | translate }}</label>
        <wp-multiline-text-box
          formControlName="description"
          placeholder="{{ 'shared2.props.description' | translate }}"
        />
      </div>
    </div>
  </div>

  <div class="form-check mt-2" title="{{ 'shared2.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared2.props.isActive.value' | translate }}
    </label>
  </div>

  <div class="actions pt-1">
    @if (service.contactForm.disabled && !service.readonly) {
      <div class="row">
        <div class="profile-btns-wrap">
          <button class="btn btn-default" (click)="makeContactEditable()">
            {{ 'shared2.actions.edit' | translate }}
          </button>
        </div>
      </div>
    }
    @if (service.contactForm.enabled) {
      <div class="row">
        <div class="profile-btns-wrap">
          <button
            type="button"
            class="btn btn-primary"
            (click)="service.save()"
            wpButtonSpinner
            [isBusy]="service.isSaving()"
          >
            {{ 'shared2.actions.save' | translate }}
          </button>
          <button class="btn btn-default ms-2" (click)="onContactEditingCancel()">
            {{ 'shared2.actions.cancel' | translate }}
          </button>
        </div>
      </div>
    }
  </div>
</form>
